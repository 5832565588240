export default function Gallery({ data }) {
  return (
    <section id="gallerie" className="instagram">
      <div className="container-fluid">
        <div className="row no-gutters justify-content-center pb-5">
          <div className="col-md-7 text-center heading-section aos-animate" data-aos="fade-up">
            <span className="subheading">Photos</span>
            <h2><span>Some photos of Hammamet Garden Yasmine-Hammamet</span></h2>
          </div>
        </div>
        <div className="row no-gutters">
          {
            data.map(image => (
              <div key={image.id} className="col-sm-12 col-md aos-animate" data-aos="fade-up">
            <a href="assets/images/insta-1.jpg" className="insta-img image-popup" style={{ backgroundImage: `url(${image.src})` }}>
              <div className="icon d-flex justify-content-center">
                <span className="icon-instagram align-self-center"></span>
              </div>
            </a>
          </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}