import React, { useEffect, useState } from "react";
import BaseService from "../../service/base.service";
import FileUploader from "../../components/file-uploader";
import { FiDelete } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
const service = new BaseService("Gallery");

function Gallery() {
  const [images, setImages] = useState([]);
  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    service.getAll().then((res) => setImages(res));
  };

  const handleCheck = (image) => {
    // update image to display or not in the client
    service
      .save(image.id, { ...image, display: !image.display })
      .then((res) => getAll());
  };

  const handleDelete = (image) => {
    // Implement the logic to delete the image
    service.delete(image.id).then((res) => getAll());
  };

  const handleUpload = (src) => {
    // Implement the logic to upload the selected image
    service.create({ src, display: true }).then((res) => getAll());
  };

  return (
    <div className="container">
      <div className="row my-2">
        {images.map((image, index) => (
          <div key={index} className="col-12 col-md-3">
            <div className="position-relative">
              <img
                src={image.src}
                alt={`${index + 1}`}
                width={"100%"}
                height={150}
              />
              <div className="d-flex justify-content-between">
              <div>afficher
                <input
                  type="checkbox"
                  checked={image.display}
                  onChange={() => handleCheck(image)}
                /></div> 
                <button
                  className="badge"
                  onClick={() => handleDelete(image)}
                >
                  <MdDeleteForever/>
                </button>
              </div>
            </div>
          </div>
        ))}
        <div className="col-12 col-md-3">
          <FileUploader
            id="file-uploader"
            onUrlReceived={handleUpload}
            html={
              <label
                style={{
                  width: "100%",
                  height: 150,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "white",
                  color: "black",
                  border: "1px solid lightgray"
                }}
                htmlFor="file-uploader"
                className="btn btn-success"
              >
                +
              </label>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Gallery;
