import React, { useState } from 'react';
import BaseService from '../service/base.service';
import { useNavigate, useParams } from 'react-router-dom';
import ReactStars from 'react-stars'

const api = new BaseService()
export default function AvisClients() {
    const [avis, setAvis] = useState({ titre: "", etoiles: 4, avis: "" });
    const { id_reservation } = useParams()
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Assuming BaseApi is properly implemented with a postAvis function
            await api.postAvis({ ...avis, id_reservation });

            // Optionally, you can show a success message or redirect the user
            alert('Merci de nous donner votre précieux avis! à bientôt!');
            navigate('/')
            // Clear the form after submission
            setAvis('');

        } catch (error) {
            console.error('Error submitting avis:', error);
            // Optionally, you can show an error message to the user
            alert('Une erreur est survenue. Veuillez réessayer.');
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <img height={250} src="/assets/images/logo.jpg" alt="Logo" />
                </div>
                <div className="col-12">
                    <h3>Merci pour votre séjour chez nous !</h3>
                    <p>Nous espérons que votre séjour à notre hôtel était agréable et que vous avez apprécié nos services.</p>
                    <p>Nous aimerions avoir votre opinion sur votre expérience. Veuillez prendre quelques instants pour remplir notre enquête de satisfaction.</p>
                </div>
                <div className="col-12">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="titre">Titre</label>
                            <input type="text" className="form-control" id='titre' value={avis.titre} onChange={e => setAvis({ ...avis, titre: e.target.value })} />
                        </div>
                        <div className="form-group">
                            <ReactStars
                                count={5}
                                value={avis.etoiles}
                                onChange={rating => setAvis({...avis, etoiles: rating})}
                                size={24}
                                color2={'#ffd700'} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="avis">Votre avis :</label>
                            <textarea
                                id="avis"
                                className="form-control"
                                rows="5"
                                value={avis.avis}
                                onChange={(e) => setAvis({ ...avis, avis: e.target.value })}
                                required
                            ></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary">Soumettre</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
