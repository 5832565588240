import { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import Admin from "./admin";
import Gallery from "./admin/modules/Gallery";
import GestionAnimationLoisir from "./admin/modules/animation-loisir";
import ConditionsChambres from "./admin/modules/conditions-chambres";
import Conditions from "./admin/modules/conditions-chambres/conditions";
import GestionLabels from "./admin/modules/conditions-chambres/gestion-labels";
import GestionSupplements from "./admin/modules/conditions-chambres/gestion-supplements";
import MinStay from "./admin/modules/conditions-chambres/min-stay";
import Pax from "./admin/modules/conditions-chambres/pax";
import PrixChambre from "./admin/modules/conditions-chambres/prix-chambre";
import ReleaseDate from "./admin/modules/conditions-chambres/release-date";
import Stock from "./admin/modules/conditions-chambres/stock";
import StopSale from "./admin/modules/conditions-chambres/stop-sale";
import GestionGastronomie from "./admin/modules/gestion-gastronomie";
import GestionInstallationsEtEspaces from "./admin/modules/gestion-installations-espaces";
import GestionSallesConferences from "./admin/modules/gestion-salles-conferences";
import HebergementsAdmin from "./admin/modules/hebergements";
import InformationHotel from "./admin/modules/information-hotel";
import Login from "./admin/modules/login";
import Marche from "./admin/modules/marche";
import Parametres from "./admin/modules/parametres";
import Profil from "./admin/modules/parametres/profil";
import Reservations from "./admin/modules/reservations";
import Situation from "./admin/modules/sitation";
import Client from "./client";
import ConfirmationReservationPaiement from "./client/confirmationReservationPaiement";
import ErreurPaiement from "./client/erreurPaiement";
import SearchResult from "./client/searchResult";
import GestionPromotions from "./admin/modules/conditions-chambres/gestion-promotion";
import ListePromotions from "./admin/modules/conditions-chambres/gestion-promotion/liste-promotion";
import FormPromotion from "./admin/modules/conditions-chambres/gestion-promotion/form-promotion";
import GestionEvenements from "./admin/modules/gestion-evenements";
import ConditionsPaiements from "./admin/modules/conditions-chambres/ConditionsPaiements";
import AvisClients from "./client/AvisClient";
import useScript from "./hooks/useScript";
import AdminAvisClients from "./admin/modules/avis-clients";
import GestionConditionsPaiement from "./admin/modules/gestion-conditions-paiement";
import PaymentPolicy from "./client/PaymentPolicy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Client />,
  },
  {
    path: "/confirmation-paiement-reservation",
    element: <ConfirmationReservationPaiement />,
  },
  {
    path: "/avis-clients/:id_reservation?",
    element: <AvisClients />,
  },
  {
    path: "/erreur-paiement",
    element: <ErreurPaiement />,
  },
  {
    path: "reservation",
    element: <SearchResult />,
  },
  {
    path: "payment-policy",
    element: <PaymentPolicy />,
  },

  {
    path: "login",
    element: <Login />,
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "avis-clients",
        element: <AdminAvisClients/>
      },
      {
        path: "page-conditions-paiements",
        element: <GestionConditionsPaiement/>
      },
      {
        path: "information-hotel",
        element: <InformationHotel />,
      },
      {
        path: "situation",
        element: <Situation />,
      },
      {
        path: "hebergements",
        element: <HebergementsAdmin />,
      },
      {
        path: "installations-espaces",
        element: <GestionInstallationsEtEspaces />,
      },
      {
        path: "salles-conferences",
        element: <GestionSallesConferences />,
      },
      {
        path: "gastronomie",
        element: <GestionGastronomie />,
      },
      {
        path: "animation-loisir",
        element: <GestionAnimationLoisir />,
      },
      {
        path: "evenements",
        element: <GestionEvenements />,
      },

      {
        path: "marches",
        element: <Marche />,
      },
      {
        path: "reservations",
        element: <Reservations />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "conditions-chambres",
        element: <ConditionsChambres />,
        children: [
          {
            path: "conditions",
            element: <Conditions />,
          },
          {
            path: "conditions-paiements",
            element: <ConditionsPaiements />,
          },
          {
            path: "release-date",
            element: <ReleaseDate />,
          },
          {
            path: "min-stay",
            element: <MinStay />,
          },
          {
            path: "stock",
            element: <Stock />,
          },
          {
            path: "stop-sale",
            element: <StopSale />,
          },
          {
            path: "promo",
            element: <GestionPromotions />,
            children: [
              {
                path: "list",
                element: <ListePromotions />,
              },
              {
                path: "form/:id?",
                element: <FormPromotion />,
              },
            ],
          },
          {
            path: "prix-chambre",
            element: <PrixChambre />,
          },
          {
            path: "prix-chambre-pax",
            element: <Pax />,
          },
          {
            path: "supplements",
            element: <GestionSupplements />,
          },
          {
            path: "labels",
            element: <GestionLabels />,
          },
        ],
      },
      {
        path: "parametres",
        element: <Parametres />,
        children: [
          {
            path: "profil",
            element: <Profil />,
          },
        ],
      },
    ],
  },
]);
function App() {
  useScript("js/main.js")
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.log(window.location.origin);
      if (
        !["https://hotelhammametgarden.tn"].includes(
          window.location.origin
        )
      ) {
        return (window.location.href =
          "https://hotelhammametgarden.tn");
      }
      if (
        window.location.origin !== "https://hotelhammametgarden.tn" &&
        window.location.origin !== "https://hotelhammametgarden.tn/admin" &&
        window.location.origin !==
          "https://hotelhammametgarden.tn/reservation"
      ) {
      }
    }
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
