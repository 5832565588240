export default function Spa({ data }) {
    return (
        <section id="spa" className="ftco-section">
            <div className="container">
                <div className="row justify-content-center mb-5 pb-3">
                    <div className="col-md-7 heading-section text-center aos-animate" data-aos="fade-up">
                        <span className="subheading">{data.label}</span>
                        <h2>Experience Serenity and Luxury at Our Spa Retreat</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-5">
                        <img width={"100%"} src={data.image} alt="" />
                    </div>
                    <div className="col-12 col-md-7">
                        <p>{data.description}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}