import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  searchResultActions,
  searchResultSelector,
} from "../app/slice/search-result.slice";
import ReservationService from "../service/reservation.service";
import moment from "moment";
import { BiChevronDown, BiSearch } from "react-icons/bi";
const service = new ReservationService();
const CURRENCIES = ["TND", "EUR", "USD"];

export default function SearchForm({ parent = "Landing" }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dateRange, roomsForms, selectedCurrency } =
    useSelector(searchResultSelector);

  const setSelectedCurrency = (currency) => {
    dispatch(searchResultActions.setSelectedCurrency(currency));
  };
  const setRoomsForms = (value) => {
    dispatch(searchResultActions.setRoomsForms(value));
  };

  const addRoom = () => {
    if (roomsForms.length > 3) return;
    setRoomsForms([
      ...roomsForms,
      { id: roomsForms.length + 1, nb_adultes: 0, nb_enfants: 0 },
    ]);
  };

  const deleteRoom = (id) => {
    setRoomsForms(roomsForms.filter((rf) => rf.id !== id));
  };

  const updateRoom = (field, value, room_id) => {
    let roomsFormWU = JSON.parse(JSON.stringify(roomsForms));
    const index = roomsForms.findIndex((rf) => rf.id == room_id);
    roomsFormWU[index][field] = value;

    if (field === "nb_enfants") {
      [...Array(value)].forEach((_, ae) => {
        roomsFormWU[index][`age_enfant_${ae + 1}`] = 0;
      });
    }
    setRoomsForms(roomsFormWU);
  };
  const submit = (e) => {
    e.preventDefault();
    const emptyRooms = roomsForms.filter(rf => {
      return rf.nb_adultes == 0 && rf.nb_enfants == 0
    })
    if(emptyRooms.length > 0) return alert('Please select rooms')
    dispatch(searchResultActions.setIsLoading());
    service
      .getHebergements({
        ...dateRange,
        nb_chambres: roomsForms.length,
        chambres: roomsForms,
        selectedCurrency,
      })
      .then((res) => {
        dispatch(searchResultActions.setResult(res));
      });
  };
  const setDateRange = (value) => {
    dispatch(searchResultActions.setDate(value));
  };
  useEffect(() => {
    let repartition = {
      nb_chambres: 0,
      nb_adultes: 0,
      nb_enfants: 0,
      nb_bebes: 0,
    };
    roomsForms.forEach((room) => {
      repartition.nb_chambres++;
      repartition.nb_adultes += room.nb_adultes;
      if (room.nb_enfants > 0) {
        for (let i = 0; i < room.nb_enfants; i++) {
          if (room[`age_enfant_${i + 1}`] > 3) repartition.nb_enfants++;
          else repartition.nb_bebes++;
        }
      }
    });
    dispatch(searchResultActions.setRepartition(repartition));
  }, [roomsForms, dispatch]);

  useEffect(() => {
    console.log(roomsForms);
  }, [roomsForms]);
  return (
    <div>
      <div
        className="modal fade modal-lg"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="rooms-form modal-content p-1">
            <div
              className={"d-flex justify-content-between align-items-center"}
              style={{
                borderBottom: "1px solid rgb(214,171,95)",
                paddingBottom: 4,
              }}
            >
              <h3>Rooms && visitors</h3>
              <div data-dismiss="modal" className={"app-button"}>
                x
              </div>
            </div>
            <button className="btn btn-success mb-2 rounded-0" onClick={addRoom}>
              +ADD ROOM
            </button>
            <div className="container">
              <div className={"row g-0"}>
                {roomsForms.map((rf) => (
                  <div key={rf.id} className=" col-4 col-md-3">
                    <div className="room-row card p-1">
                      <div className={"d-flex card-header"}>
                        <span>Room {rf.id} </span>
                        {rf.id === roomsForms.length && rf.id !== 1 && (
                          <span
                            style={{ marginLeft: 4 }}
                            onClick={() => deleteRoom(rf.id)}
                            className={"app-button-small"}
                          >
                            x
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <small>Adult(s)</small>
                        <select
                          value={rf.nb_adultes}
                          onChange={(e) =>
                            updateRoom(
                              "nb_adultes",
                              Number(e.target.value),
                              rf.id
                            )
                          }
                          className={"form-control"}
                          name=""
                          id=""
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <small>Children</small>
                        <select
                          value={rf.nb_enfants}
                          onChange={(e) =>
                            updateRoom(
                              "nb_enfants",
                              Number(e.target.value),
                              rf.id
                            )
                          }
                          className={"form-control"}
                          name=""
                          id=""
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div>
                      {[...Array(rf.nb_enfants)].map((_, i) => (
                        <div className="form-group" key={i}>
                          <small>Age Child ({i + 1})</small>
                          <select
                            onChange={(e) =>
                              updateRoom(
                                `age_enfant_${i + 1}`,
                                Number(e.target.value),
                                rf.id
                              )
                            }
                            className={"form-control"}
                            name=""
                            id=""
                          >
                            <option value="1">{"< 1"}</option>
                            <option value="2">{"< 2"}</option>
                            <option value="3">{"< 3"}</option>
                            <option value="4">{"< 4"}</option>
                            <option value="5">{"< 5"}</option>
                            <option value="6">{"< 6"}</option>
                            <option value="7">{"< 7"}</option>
                            <option value="8">{"< 8"}</option>
                            <option value="9">{"< 9"}</option>
                            <option value="10">{"< 10"}</option>
                            <option value="11">{"< 11"}</option>
                            <option value="12">{"< 12"}</option>
                          </select>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <button data-dismiss="modal" className={"app-button mt-2"}>
              Valider
            </button>
          </div>
        </div>
      </div>
      <div id={parent === "Landing" ? "search-form" : ""}>
        <div className="container">
          <div className="reservation">
            <form onSubmit={submit} className={"row"}>
              <div className="form-group col-12 col-md-2">
                <label htmlFor="">Check-in date</label>
                <input
                  min={moment().add('1', 'day').format("YYYY-MM-DD")}
                  value={dateRange.du}
                  onChange={(e) =>
                    setDateRange({ ...dateRange, du: e.target.value })
                  }
                  type="date"
                  className="form-control"
                />
              </div>
              <div className="form-group col-12 col-md-2">
                <label htmlFor="">Check-out date</label>
                <input
                  min={moment(dateRange.du).add(1, "days").format("YYYY-MM-DD")}
                  value={dateRange.au}
                  onChange={(e) =>
                    setDateRange({ ...dateRange, au: e.target.value })
                  }
                  type="date"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="">Rooms</label>
                <div
                  data-toggle="modal"
                  data-target="#exampleModal"
                  className={"input-group"}
                >
                  <input
                    placeholder={
                      roomsForms.length +
                      " room(s), " +
                      roomsForms.reduce((a, b) => a + b.nb_adultes, 0) +
                      "Adult(s), " +
                      roomsForms.reduce((a, b) => a + b.nb_enfants, 0) +
                      "Children"
                    }
                    style={{ borderRight: 0 }}
                    type="text"
                    className="form-control"
                  />
                  <span className="input-group-text">
                    <BiChevronDown />
                  </span>
                </div>
              </div>



              <div className={"col-12 col-md-2 d-flex text-align-end"}>
                {parent === "Landing" ? (
                  <button
                    onClick={() => navigate("/reservation")}
                    type={"submit"}
                    className={"btn"}
                  >
                    <BiSearch /> Search
                  </button>
                ) : (
                  <button
                    type={"submit"}
                    className={"app-button mt-auto w-100"}
                    style={{ maxHeight: "unset", height: 39, marginBottom: 12 }}
                  >
                    <BiSearch />
                    Search
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
