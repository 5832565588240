import uploadFileService from "../service/upload-file.service";
import {useState} from "react";

export default function FileUploader({id, onUrlReceived, html}){
    const uploadFile = e => {
        let currentFile = e.target.files[0]
        uploadFileService.upload(currentFile)
            .then(res => {
                onUrlReceived(res.data.url)
            })

    }
    return (
        <div style={{width: "100%"}}>
            {html}
            <input onChange={uploadFile} id={id} type="file" hidden/>
        </div>
    )
}
