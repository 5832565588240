export default function Evenements({ data }) {
    return (
        <section id="events" className="ftco-section">
            <div className="container">
                <div className="row justify-content-center mb-5 pb-3">
                    <div className="col-md-7 heading-section text-center aos-animate" data-aos="fade-up">
                        <span className="subheading">Events</span>
                        <h2>And Conferences</h2>
                    </div>
                </div>
                <div className="row d-flex">
                    {
                        data.map(c => (
                            <div key={c.id} className={`col-md-${12/data.length} d-flex aos-animate`} data-aos="fade-up">
                                <div className="blog-entry align-self-stretch w-100">
                                    <img alt="#" className="block-20 rounded" src={c.image} width={"100%"}>
                                    </img>
                                    <div className="text mt-3 text-center">
                                        <div className="meta mb-2">
                                            <div><a href="#">{c.label}</a></div>
                                        </div>
                                        <h3 className="heading"><a href="#">{c.description}</a></h3>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}