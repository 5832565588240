import React from 'react';
import OwlCarousel from "react-owl-carousel";
import moment from "moment"
const AvisClients = ({ data }) => {
  return (
    <section className="testimony-section">
      <div className="container">
        <div className="row no-gutters aos-animate justify-content-center" data-aos="fade-up">
          <div className="col-md-5 d-flex">
            <div className="testimony-img aside-stretch-2" style={{ backgroundImage: 'url(assets/images/testimony-img.jpg)' }}></div>
          </div>
          <div className="col-md-7 py-5 pl-md-5">
            <div className="py-md-5">
              <div className="heading-section aos-animate mb-4" data-aos="fade-up">
                <span className="subheading">What our clients are saying about us</span>
                <h2 className="mb-0">Reviews</h2>
              </div>
              <OwlCarousel className='carousel-testimony' loop items={1} animateIn={"fade"}>
                {
                  data.map(d => (
                    <div key={d.id} className="slider-item">
                      <div className="testimony-wrap pb-4">
                        <div className="text">
                          <p className="mb-4">
                            {d.avis}
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="user-img" style={{ backgroundImage: 'url(assets/images/profile_png.jpg)' }}></div>
                          <div className="pos ml-3">
                            <p className="name">{d.nom}</p>
                            <span className="position">{moment(d.date).format("DD/MM/YYYY")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </OwlCarousel>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AvisClients;
