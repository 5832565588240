export default function AppModal({modalTitle, content, actions, onCloseClicked}){
    return (
        <div className={"app-modal-container"}>
            <div className={"app-modal"}>
                <div className="app-modal-header">
                    <h4>{modalTitle}</h4>
                    <button className={"app-button"} onClick={onCloseClicked}>X</button>
                </div>
                <div className="app-modal-content">
                    {content}
                </div>
                <div className="app-modal-footer">
                    {
                        actions && actions.map(a => (
                            <button key={a.text} onClick={a.action} className={"app-button"}>{a.text}</button>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
