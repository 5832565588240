import axios from "axios";
import { API_URL, API_URL_V2 } from "./constants";

export default axios.create({
    baseURL: "http://localhost:3001/api/",
    headers: {
        "Content-type": "application/json"
    }
});

export const axiosToken = headers => axios.create({
    baseURL: API_URL,
    headers: {
        ...headers,
        "x-auth-token": localStorage.getItem("token")
    }
})
export const axiosTokenV2 = headers => axios.create({
    baseURL: API_URL_V2,
    headers: {
        ...headers,
        "x-auth-token": localStorage.getItem("token")
    }
})
