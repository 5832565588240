import { useEffect, useState } from "react";
import moment from "moment";
import { BiTrash } from "react-icons/bi";
import BaseService from "../../../service/base.service";
const api = new BaseService()
export default function AdminAvisClients() {
    const [rows, setRows] = useState([]);
    const handleAffichage = (afficher, id) => {
        api.editAffichageAvis(afficher, id)
        .then(res => getRows())
    }

    const deleteAvis = id => {
        api.deleteAvis(id)
        .then(res => getRows())
    }

    useEffect(() => {
        getRows()
    }, [])

    const getRows = () => {
        api.getAvis()
            .then(res => setRows(res))
    }
    return (
        <div className="container-fluid">
            <h3 className="border-bottom">Avis des clients</h3>
            <div className="table-responsive">
                <table className="table table-sm table-hover table-striped table-bordered">
                    <thead className="table-dark">
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Client</th>
                            <th>Titre</th>
                            <th>Avis</th>
                            <th>étoiles</th>
                            <th>Afichage</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.map(r => (
                                <tr key={r.id}>
                                    <td>{r.id}</td>
                                    <td>{moment(r.date).format("DD/MM/YYYY")}</td>
                                    <td>{r.nom} - {r.tel} - {r.email} - {r.adresse}</td>
                                    <td>{r.titre}</td>
                                    <td>{r.avis}</td>
                                    <td>{r.etoiles}</td>
                                    <td>
                                        <input type="checkbox" checked={r.afficher} onChange={e => handleAffichage(e.target.checked, r.id)} />
                                    </td>
                                    <td>
                                        <button onClick={() => deleteAvis(r.id)} className="btn-danger btn"><BiTrash /> Supprimer</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}