import { createSlice } from "@reduxjs/toolkit";
import evenementThunk from "../thunk/evenement-thunk";
import moment from "moment";

const initialState = {
  rows: [],
  form: {
    label: "",
    etat: 1, // actif || !actif
    application_par: "", // nuit || séjour
    application_sur: "", // pax || chambre
    exigence: "", // optionel || obligatoire
    date_debut_sejour: "",
    date_fin_sejour: "",
    date_debut_reservation: "",
    date_fin_reservation: "",
    description_prix_personnes: "",
    description_prix_chambres: "",
  },
  error: null,
  loading: false,
  chambres: [],
  personnes: [
    {
      id: 1,
      categorie: "adulte",
      valeurs: [],
    },
    {
      id: 2,
      categorie: "enfant",
      valeurs: [],
    },
    {
      id: 3,
      categorie: "bebe",
      valeurs: [],
    },
  ],
  dates_sejours: [],
};

const evenementSlice = createSlice({
  name: "evenement",
  initialState,
  reducers: {
    setState: (state, { payload }) => ({ ...state, ...payload }),
    resetForm: (state) => ({
      ...state,
      form: initialState.form,
      personnes: initialState.personnes,
      chambres: state.chambres.map((ch) => {
        return { id: ch.id, categorie: ch.categorie, valeurs: [] };
      }),
    }),
    handleChangeEntite: (state, { payload }) => {
      const { index, index_ds, valeur } = payload;
      const entites =
        state.form.application_sur === "personne"
          ? state.personnes
          : state.chambres;
      entites[index].valeurs[index_ds].valeur = valeur;
    },
    handleRefactorEntites: (state, { payload }) => {
      const { chambres, personnes, form } = state;
      const dates_sejours = payload;
      const updated = (
        form.application_sur === "personne" ? personnes : chambres
      ).map((entite) => {
        const valeurs = dates_sejours.map((ds) => {
          const valeur = entite.valeurs.find((v) => v.date === ds)?.valeur;
          return {
            date: ds,
            valeur: valeur ?? 0,
          };
        });
        return { ...entite, valeurs };
      });

      return {
        ...state,
        chambres: form.application_sur === "chambre" ? updated : chambres,
        personnes: form.application_sur === "personne" ? updated : personnes,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(evenementThunk.getAll.fulfilled, (state, { payload }) => {
        state.rows = payload;
      })
      .addCase(evenementThunk.get.fulfilled, (state, { payload }) => {
        state.form = payload;
        state.chambres = JSON.parse(payload.description_prix_chambres)
        state.personnes = JSON.parse(payload.description_prix_personnes)
        let currentDate = moment(payload.date_debut_sejour);
        const dates_sejours = []
        while (currentDate.isSameOrBefore(payload.date_fin_sejour)) {
          dates_sejours.push(currentDate.format("DD/MM/YYYY"));
          currentDate.add(1, "day");
        }
        state.dates_sejours = dates_sejours
      })
      .addCase(evenementThunk.create.fulfilled, (state, { payload }) => {
        state.form = payload;
        state.rows.push(payload);
      })
      .addCase(evenementThunk.save.fulfilled, (state, { payload }) => {
        const index = state.rows.findIndex((r) => r.id === payload.id);
        state.rows[index] = payload;
      })
      .addCase(evenementThunk.delete.fulfilled, (state, { payload }) => {
        state.rows = state.rows.filter((r) => {
          return r.id !== payload;
        });
        if (state.form.id === payload) state.form = initialState.form;
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, { payload }) => {
          console.log(payload);
          state.loading = false;
          state.error = payload;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.loading = false;
          state.error = null;
        }
      );
  },
});

export default evenementSlice.reducer;
export const evenementActions = evenementSlice.actions;
export const evenementStateSelector = (state) => state.evenement;
