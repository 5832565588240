import axios from "axios";
import { API_URL} from "../config/constants";
import BaseService from "./base.service";

class VideoBulletsService extends BaseService {
  constructor() {
    super("SectionVideoBullets");
    this.API_URL = API_URL + "video-bullets/";
  }

  async getAll() {
    return await axios
      .get(this.API_URL, {
        headers: {
          "x-auth-token": localStorage.getItem('token'),
        },
      })
      .then((res) => res.data);
  }

  async saveAll(data) {
    return await axios
      .post(this.API_URL, data, {
        headers: {
          "content-type": "application/json",
          "x-auth-token": localStorage.getItem('token'),
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  }
}

export default VideoBulletsService;
