import moment from "moment/moment"

export const API_URL = process.env.NODE_ENV === "production" ? "https://api.kreatek.tn/hammamet-garden/api/" : "http://localhost:3001/api/"
export const API_URL_V2 = process.env.NODE_ENV === "production" ? "https://api.kreatek.tn/hammamet-garden/apiv2/" : "http://localhost:3001/apiv2/"
export const UPLOAD_URL = process.env.NODE_ENV === "production" ? "https://api.kreatek.tn/hammamet-garden/upload/" : "http://localhost:3001/upload"
export const APP_URL = process.env.NODE_ENV === "production" ? "https://hotel-hammamet-garden.com/" : "http://localhost:3001/"

export const formatDateFr = date => moment(date).format("DD/MM/YYYY")


export const choices = [
    { id: 1, label: "Diner gala seulement", price: 150 },
    { id: 2, label: "Diner gala plus logement et brunch", price: 210 },
    {
      id: 3,
      label: "Séjour 02 nuitées (30 et 31/12) plus diner gala",
      price: 260,
    },
    {
      id: 4,
      label: "Séjour 03 nuitées (29, 30 et 31/12) plus diner gala",
      price: 310,
    },
  ];