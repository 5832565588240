import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router

import BaseService from "../service/base.service";
import { BiCalendar } from "react-icons/bi";
import Welcome from "./Welcome";
import About from "./About";
import AvisClients from "./AvisClients";
import Chambres from "./Chambres";
import Restaurants from "./Restaurants";
import Gallery from "./Gallery";
import Evenements from "./Evenements";
import Contact from "./Contact";
import { useDispatch, useSelector } from "react-redux";
import { clientActions, clientSelector } from "../app/slice/client.slice";
import Hero from "./Hero";
import Spa from "./Spa";
const service = new BaseService("InformationHotel");

function Client() {
  const { landingData } = useSelector(clientSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    service
      .getLandingData()
      .then((res) => dispatch(clientActions.setLandingData(res)));
  }, [dispatch]);

  if (!landingData) return <>Loading...</>;
  const { heroSliders, informationsHotel, chambres, gastronomie, images, salleConference, avisClients : avisRows, spa } = landingData;
  return (
    <>
         <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">

        <div className="container">
          <Link className="navbar-brand" to="/">
            Hammamet<span>Garden</span>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#ftco-nav"
            aria-controls="ftco-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="oi oi-menu"></span> Menu
          </button>

          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a href="#welcome" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="#about" className="nav-link">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a href="#rooms" className="nav-link">
                  Rooms
                </a>
              </li>
              <li className="nav-item">
                <a href="#restaurants" className="nav-link">
                  Restaurants
                </a>
              </li>
              <li className="nav-item">
                <a href="#gallery" className="nav-link">
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <a href="#events" className="nav-link">
                  Events
                </a>
              </li>
              <li className="nav-item">
                <a href="#spa" className="nav-link">
                  Spa
                </a>
              </li>

              <li className="nav-item">
                <a href="#contact" className="nav-link">
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <Link
                  to="/reservation"
                  className="nav-link d-flex align-items-center"
                >
                  <BiCalendar />
                  Book Now
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <Hero data={heroSliders}/>
      <Welcome />
      <About data={informationsHotel} />
      <AvisClients data={avisRows} />
      <Chambres data={chambres} />
      <Restaurants data={gastronomie} />
      <Gallery data={images} />
      <Evenements data={salleConference} />
      <Spa data={spa} />
      <Contact data={informationsHotel}/>

      <footer
        className="ftco-footer ftco-section img"
        style={{ backgroundImage: "url(assets/images/bg_4.jpg)" }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row mb-5">
            <div className="col-md">
              <div className="ftco-footer-widget mb-4">
                <img src="assets/images/logo-footer.png" width={"100%"} alt="" />
                <p>
                  
                </p>
                <ul className="text-center w-100 ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                  <li className="aos-animate" data-aos="fade-up">
                    <a href="https://www.facebook.com/p/Hammamet-garden-Resort-spa-100057641943003/" target="_blank" rel="noreferrer">
                      <span className="icon-facebook"></span>
                    </a>
                  </li>
                  <li className="aos-animate" data-aos="fade-up">
                    <a href="https://www.instagram.com/hammamet.garden/" target="_blank" rel="noreferrer">
                      <span className="icon-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md">
              <div className="ftco-footer-widget mb-4 ml-md-5">
                <h2 className="ftco-heading-2">Hotel Sections</h2>
                <ul className="list-unstyled">
                  <li>
                    <a href="#rooms" className="py-2 d-block">
                      Rooms
                    </a>
                  </li>
                  <li>
                    <a href="#restaurants" className="py-2 d-block">
                      Restaurants
                    </a>
                  </li>
                  <li>
                    <a href="#events" className="py-2 d-block">
                      Events
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">Information</h2>
                <ul className="list-unstyled">
                  <li>
                    <a href="#about" className="py-2 d-block">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="#gallery" className="py-2 d-block">
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a href="#contact" className="py-2 d-block">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="/reservation" className="py-2 d-block">
                      Reservations
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">Have a Question?</h2>
                <div className="block-23 mb-3">
                  <ul>
                    <li>
                      <span className="icon icon-map-marker"></span>
                      <span className="text">Hammamet, Tunisia</span>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon icon-phone"></span>
                        <p className="text">+216 {informationsHotel.tel1}</p>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon icon-envelope"></span>
                        <span className="text">{informationsHotel.email1}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <p>
                Copyright &copy;
                <script>document.write(new Date().getFullYear());</script> All
                rights reserved | Powered by 
                <a className="ml-1" href="https://kreatek.tn" target="_blank" rel="noreferrer">
                    KREATEK.TN
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Client;
