import { useState } from "react";
import BaseService from "../service/base.service";
import ContactMap from "../components/MapComponent";
const service = new BaseService()
export default function Contact({ data }) {
  const [form, setForm] = useState({ name: "", subject: "", message: "", email: "" });

  const submit = e => {
    e.preventDefault()
    service.postContact(form)
      .then(res => { window.location.reload() })
      .catch(error => console.error(error))
  }
  return (
    <section id="contact" className="ftco-section contact-section bg-light">
      <div className="container">
        <div className="row d-flex mb-5 contact-info">
          <div className="col-md-12 mb-4">
            <h2 className="h3">Contact</h2>
          </div>
          <div className="w-100"></div>
          <div className="col-md-6 d-flex">
            <div className="info rounded bg-white p-4">
              <p><span>Address:</span> {data.localisation}</p>
            </div>
          </div>
          <div className="col-md-3 d-flex">
            <div className="info rounded bg-white p-4">
              <p><span>Phone:</span> <a href={`tel://${data.tel1}`}>+216 {data.tel1}</a></p>

            </div>
          </div>
          <div className="col-md-3 d-flex">
            <div className="info rounded bg-white p-4">
              <p><span>Email:</span> <a href={`mailto:${data.email1}`}>{data.email1}</a></p>
            </div>
          </div>

        </div>
        <div className="row block-9">
          <div className="col-md-6 order-md-last d-flex">
            <form onSubmit={submit} className="bg-white p-5 contact-form">
              <div className="form-group">
                <input required value={form.name} onChange={e => setForm({ ...form, name: e.target.value })} type="text" className="form-control" placeholder="Your Name" />
              </div>
              <div className="form-group">
                <input required value={form.email} onChange={e => setForm({ ...form, email: e.target.value })} type="email" className="form-control" placeholder="Your Email" />
              </div>
              <div className="form-group">
                <input required value={form.subject} onChange={e => setForm({ ...form, subject: e.target.value })} type="text" className="form-control" placeholder="Subject" />
              </div>
              <div className="form-group">
                <textarea required value={form.message} onChange={e => setForm({ ...form, message: e.target.value })} name="" id="" cols="30" rows="7" className="form-control" placeholder="Message"></textarea>
              </div>
              <div className="form-group">
                <input type="submit" value="Envoyer le message" className="btn btn-primary py-3 px-5" />
              </div>
            </form>

          </div>

          <div className="col-md-6 d-flex">
            {/* <iframe 
    title="map" 
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12845.362119659729!2d10.5675462!3d36.4009556!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd61fc0da7e8ef%3A0x585d4ef88350afa8!2sHammamet%20Garden!5e0!3m2!1sen!2stn!4v1706531562516!5m2!1sen!2stn&maptype=roadmap" 
    width="100%" 
    height="100%" 
    style={{ border: 0 }} 
    allowFullScreen="" 
    loading="lazy" 
    referrerPolicy="no-referrer-when-downgrade">
</iframe> */}
            <ContactMap />
          </div>
        </div>
      </div>
    </section>
  )
}