import { BiSave, BiWindowClose } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  evenementActions,
  evenementStateSelector,
} from "../../../app/slice/evenement-slice";
import evenementThunk from "../../../app/thunk/evenement-thunk";
import moment from "moment";

const ApplicationEntites = () => {
  const { chambres, dates_sejours, personnes, form } = useSelector(
    evenementStateSelector
  );
  const dispatch = useDispatch();
  const application = form.application_sur;
  const entites = application === "personne" ? personnes : chambres;
  console.log(personnes);
  return (
    <div className="container mt-2">
      <h5>{application}s</h5>
      <table className="table table-sm table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Entité</th>
            {dates_sejours.map((ds) => (
              <th key={ds}>{ds}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {entites.map((e, index) => (
            <tr key={e.id}>
              <td>{index + 1}</td>
              <td>{e.categorie}</td>
              {e.valeurs.map((ds, index_ds) => (
                <td key={`${index}-${ds.date}`}>
                  <input
                    onClick={(e) => e.target.select()}
                    type="text"
                    className="form-control"
                    value={ds.valeur}
                    onChange={(e) =>
                      dispatch(
                        evenementActions.handleChangeEntite({
                          index,
                          index_ds,
                          valeur: e.target.value,
                        })
                      )
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default function FormEvenement() {
  const { form, personnes, chambres, rows } = useSelector(
    evenementStateSelector
  );
  const dispatch = useDispatch();
  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    const newForm = { ...form, [name]: value };
    let dates_sejours = [];

    if (newForm.date_debut_sejour !== "" && newForm.date_fin_sejour !== "") {
      let currentDate = moment(newForm.date_debut_sejour);

      while (currentDate.isSameOrBefore(newForm.date_fin_sejour)) {
        dates_sejours.push(currentDate.format("DD/MM/YYYY"));
        currentDate.add(1, "day");
      }
    } else {
      dates_sejours = [];
    }
    dispatch(evenementActions.handleRefactorEntites(dates_sejours));


    dispatch(evenementActions.setState({ form: newForm, dates_sejours }));
  };


  const submit = (e) => {
    e.preventDefault();
    const dispatchAction = form.id
      ? evenementThunk.save
      : evenementThunk.create;
    dispatch(
      dispatchAction({
        ...form,
        description_prix_chambres: JSON.stringify(chambres),
        description_prix_personnes: JSON.stringify(personnes),
      })
    );
  };

  const resetForm = () => {
    dispatch(evenementActions.resetForm());
  };

  const renderApplication = () => {
    if (form.date_debut_sejour === "" || form.date_fin_sejour === "")
      return <small style={{color: "red", textDecoration: "underline"}}>Veuillez remplir les dates séjours</small>;
    return <ApplicationEntites />;
  };
  return (
    <form onSubmit={submit}>
      <div className="row border-bottom pb-2">
        <div className="col-12 col-md-4">
          <label htmlFor="label">Libellé</label>
          <input
            value={form.label}
            onChange={handleChangeForm}
            name="label"
            id="label"
            type="text"
            className="form-control"
            required
          />
        </div>
        <div className="col-12 col-md-4">
          <label htmlFor="label-select">Libellé (anc.)</label>
          <select
            value={form.label}
            onChange={handleChangeForm}
            name="label"
            id="label-select"
            className="form-control"
          >
            <option value="">-- Séléctionner --</option>
            {
              rows
              .map(e => (
                <option key={e.id} value={e.label}>{e.label}</option>
              ))
            }
          </select>
        </div>
        <div className="col-12 col-md-4">
          <label htmlFor="etat">État</label>
          <select
            value={form.etat}
            onChange={handleChangeForm}
            name="etat"
            id="etat"
            className="form-control"
            required
          >
            <option value="">-- État --</option>
            <option value="true">Activé</option>
            <option value="false">Désactivé</option>
          </select>
        </div>
      </div>
      <div className="row border-bottom pb-2">
        {/* application par */}
        <div className="col-12 col-md-4">
          <label htmlFor="application_par">Application par</label>
          <select
            value={form.application_par}
            onChange={handleChangeForm}
            name="application_par"
            id="application_par"
            className="form-control"
            required
          >
            <option value="">-- Veuillez choisir --</option>
            <option value="nuit">Nuit</option>
            <option value="sejour">Séjour</option>
          </select>
        </div>
        {/* application sur */}
        <div className="col-12 col-md-4">
          <label htmlFor="application_sur">Application sur</label>
          <select
            value={form.application_sur}
            onChange={handleChangeForm}
            name="application_sur"
            id="application_sur"
            className="form-control"
            required
          >
            <option value="">-- Veuillez choisir --</option>
            <option value="personne">Personne</option>
            <option value="chambre">Chambre</option>
          </select>
        </div>

        {/* éxigence */}

        <div className="col-12 col-md-4 pt-3">
          <div className="form-check form-check-inline">
            <input
              onChange={handleChangeForm}
              className="form-check-input"
              type="radio"
              name="exigence"
              id="optionel"
              value="optionel"
              required
              checked={form.exigence === "optionel"}
            />
            <label className="form-check-label" htmlFor="optionel">
              Optionel
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              onChange={handleChangeForm}
              className="form-check-input"
              type="radio"
              name="exigence"
              id="obligatoire"
              value="obligatoire"
              required
              checked={form.exigence === "obligatoire"}
            />
            <label className="form-check-label" htmlFor="obligatoire">
              Obligatoire
            </label>
          </div>
        </div>
      </div>
      <div className="row border-bottom pb-2">
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="date_debut_sejour">Date début séjour</label>
            <input
              value={form.date_debut_sejour}
              onChange={handleChangeForm}
              type="date"
              name="date_debut_sejour"
              id="date_debut_sejour"
              className="form-control"
              required
            />
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="date_fin_sejour">Date fin séjour</label>
            <input
              value={form.date_fin_sejour}
              onChange={handleChangeForm}
              type="date"
              name="date_fin_sejour"
              id="date_fin_sejour"
              className="form-control"
              required
            />
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="date_debut_reservation">
              Date début réservation
            </label>
            <input
              value={form.date_debut_reservation}
              onChange={handleChangeForm}
              type="date"
              name="date_debut_reservation"
              id="date_debut_reservation"
              className="form-control"
              required
            />
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="date_fin_reservation">Date fin réservation</label>
            <input
              value={form.date_fin_reservation}
              onChange={handleChangeForm}
              type="date"
              name="date_fin_reservation"
              id="date_fin_reservation"
              className="form-control"
              required
            />
          </div>
        </div>

        {form.application_sur && (
          <div className="col-12">
            <h4 className="border-bottom mt-3">Application</h4>
            {renderApplication()}
          </div>
        )}
      </div>

      <div className="row justify-content-end pr-3 mt-2 border-bottom pb-2">
        {form.id && (
          <button onClick={resetForm} type="button" className="btn-danger mr-3">
            <BiWindowClose /> Créer nouveau
          </button>
        )}
        <button>
          <BiSave /> Enregistrer l'événement
        </button>
      </div>
    </form>
  );
}
