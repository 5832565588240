import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    filterForm: {
        du: "",
        au: "",
        rooms: []
    },
    form: {
        du: "",
        au: "",
        rooms: []
    },
    data: {
        rooms: [],
        stopSales: []
    },
    loading: false
}

const stopSaleSlice = createSlice({
    name: "stopSale",
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        setForm: (state, action) => {
            const {field, value, form} = action.payload
            if(field === "rooms"){
                if(state[form].rooms.find(r => r === value)) state[form].rooms = state[form].rooms.filter(r => r !== value)
                else state[form].rooms.push(value)
            }
            else state[form][field] = value
        },
        populateForm: (state, action) => {
           state.form = action.payload
        },
        resetForm: (state, action) => {
           state.form = initialState.form
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const stopSaleStateSelector = state => state.stopSale
export const stopSaleActions = {...stopSaleSlice.actions}
export default stopSaleSlice.reducer
