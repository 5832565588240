import { API_URL } from "../config/constants";
import { axiosToken } from "../config/http-common";
import BaseService from "./base.service";

export default class PromotionService extends BaseService {
  constructor() {
    super("Promotion");
    this.API_URL = API_URL + "pv/promotions/";
  }


  async createPromotion(data) {
    return await axiosToken({ "Content-Type": "application/json" })
      .post(this.API_URL + "create-promotion", data)
      .then((res) => res.data);
  }
  async updatePromotion(data) {
    return await axiosToken({ "Content-Type": "application/json" })
      .put(this.API_URL + "save-promotion", data)
      .then((res) => res.data);
  }

  async savePromotionConditions(data) {
    return await axiosToken({ "Content-Type": "application/json" })
      .post(this.API_URL + "save-promotion-conditions", data)
      .then((res) => res.data);
  }

  async savePromotionReduction(data) {
    return await axiosToken({ "Content-Type": "application/json" })
      .post(this.API_URL + "save-promotion-reduction", data)
      .then((res) => res.data);
  }

  async getData() {
    return await axiosToken()
      .get(this.API_URL + "get-data")
      .then((res) => res.data);
  }

  async getPromotionForUpdate(id){
    return await axiosToken()
      .get(this.API_URL + "get/" + id)
      .then(res => res.data)
  }
}
