import React from 'react';
import { BiCalendar } from 'react-icons/bi';

const About = ({data}) => {
  console.log(data);
  return (
    <section className="ftco-section ftco-wrap-about ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-md-7 order-md-last d-flex">
            <div className="img img-1 mr-md-2 aos-animate" data-aos="fade_up" style={{ backgroundImage: 'url(assets/images/about-1.jpg)' }}></div>
            <div className="img img-2 ml-md-2 aos-animate" data-aos="fade_up" style={{ backgroundImage: 'url(assets/images/about-2.jpg)' }}></div>
          </div>
          <div className="col-md-5 wrap-about pb-md-3 aos-animate pr-md-5 pb-md-5 pt-md-4" data-aos="fade_up">
            <div className="heading-section mb-4 my-5 my-md-0">
              <span className="subheading">{data.a_propos_titre}</span>
              <h2 className="mb-4">{data.a_propos_sous_titre}</h2>
            </div>
            <p>.</p>
            <p><a href="#" className="btn btn-secondary rounded"><BiCalendar/> Book your stay</a></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
