import Slider from "react-slick";
import { API_URL } from "../config/constants";
import { axiosToken } from "../config/http-common";
import axios from "axios";

class SliderApi {
  constructor() {
    this.endPoint = API_URL + "native/v2/sliders";
  }

  async getAll() {
    return await axios.get(this.endPoint).then((res) => res.data);
  }

  async create(data) {
    return await axiosToken({ "Content-Type": "application/json" })
      .post(this.endPoint, data)
      .then((res) => res.data);
  }
  async update(data) {
    return await axiosToken({ "Content-Type": "application/json" })
      .put(this.endPoint, data)
      .then((res) => res.data);
  }
  async delete(id) {
    return await axiosToken({ "Content-Type": "application/json" })
      .delete(this.endPoint + "/" + id)
      .then((res) => res.data);
  }
}

const sliderApi = new SliderApi();
export default sliderApi;
