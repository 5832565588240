import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { reservationsActions } from "../app/slice/reservations-slice";
import ReservationService from "../service/reservation.service";
import axios from "axios";
const service = new ReservationService();

export default function ConfirmationReservationPaiement() {
  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem("reservation")));
    const reservation = JSON.parse(
      localStorage.getItem("reservation")
        ? localStorage.getItem("reservation")
        : null
    );
    if (reservation !== null) {
      service
        .save(reservation)
        .then((_) => {
          localStorage.removeItem("reservation");
          window.opener.postMessage('reloadPage', '*');
        })
        .catch((error) => console.log(error));
    }
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <img className="mx-auto" src="assets/images/logo.jpg" width={250} alt=""/>
      <p className="text-center">Thank you for choosing our hotel.</p>
      <p className="text-center">
        Your reservation has been confirmed and we have sent an email
        containing the reservation voucher.
      </p>
      <strong className={"mt-2 text-center"}>
        If you have any questions or concerns, please do not hesitate to
        contact us. We look forward to welcoming you soon!
      </strong>
    </div>
  );
}
