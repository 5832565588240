export default function Chambres({ data }) {
	return (
		<section id="rooms" className="ftco-section ftco-no-pb ftco-room">
			<div className="container-fluid px-0">
				<div className="row no-gutters justify-content-center mb-5 pb-3">
					<div className="col-md-7 heading-section text-center aos-animate" data-aos="fade-up">
						<span className="subheading">Hammamet Garden Rooms</span>
						<h2 className="mb-4">Hotel Master's Rooms</h2>
					</div>
				</div>
				<div className="row no-gutters">
					{
						data.map(chambre => (
							<div key={chambre.id} className="col-lg-6">
								<div className="room-wrap d-md-flex aos-animate" data-aos="fade-up">
									<a href="/reservation" className="img" style={{ backgroundImage: `url(${chambre.image_hebergements[0].image})` }}></a>
									<div className="half left-arrow d-flex align-items-center">
										<div className="text p-4 text-center">
											<p className="star mb-0"><span className="ion-ios-star"></span><span className="ion-ios-star"></span><span className="ion-ios-star"></span><span className="ion-ios-star"></span><span className="ion-ios-star"></span></p>
											{/* <p className="mb-0"><span className="price mr-1">$120.00</span> <span className="per">per night</span></p> */}
											<h3 className="mb-3"><a href="/reservation">{chambre.categorie}</a></h3>
											<p className="pt-1"><a href="/reservation" className="btn-custom px-3 py-2 rounded">Book now <span className="icon-long-arrow-right"></span></a></p>
										</div>
									</div>
								</div>
							</div>

						))
					}
				</div>
			</div>
		</section>
	)
}