import OwlCarousel from 'react-owl-carousel';


const Hero = ({ data }) => {
  return (
    <div className="hero">
      <OwlCarousel className='home-slider' loop items={1} autoplay autoplayTimeout={5000} animateIn={"fade"}>
        {
          data.map(d => (
            <div key={d.id} className='slider-item' style={{ backgroundImage: `url(${d.image})` }}>
              <div className="overlay"></div>
              <div className="container">
                <div className="row no-gutters slider-text align-items-center justify-content-end">
                  <div className="col-md-6">
                    <div className="text animate__animated animate__fadeIn">
                      <h2>{d.titre}</h2>
                      <h1 className="mb-3">{d.sous_titre}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </OwlCarousel>
    </div>
  );
};

export default Hero;
