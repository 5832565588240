import SelectMarche from "../../../components/select-marche";

export default function ReleaseDate(){
    return (
        <div className={"row"}>
            <div className="col-12">
                <h3>Release date</h3>
            </div>
            <div className="col-12 col-md-2">
                <form action="">
                    <div className="form-group">
                        <SelectMarche/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Release date</label>
                        <select name="" id="" className={"form-select"}>
                            <option value="">0</option>
                            <option value="">1</option>
                            <option value="">2</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Date début</label>
                        <input type="date" className={"form-control"}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Date Fin</label>
                        <input type="date" className={"form-control"}/>
                    </div>

                    <button className={"btn btn-success btn-sm"} style={{float: "right"}}><i className={"bi-save"}/>Enregistrer</button>
                </form>
            </div>

            <div style={{borderLeft: "1px solid"}} className="col-12 col-md-10">
                <table className="table table-bordered table-sm">
                    <thead className={"table-dark"}>
                    <tr>
                        <th>#</th>
                        <th>Début</th>
                        <th>Fin</th>
                        <th>Release Date </th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>
    )
}
