import { useEffect, useState } from "react";
import HebergementService from "../../../service/hebergement.service";
import BaseService from "../../../service/base.service";
import moment from "moment";

const service = new HebergementService();
const serviceMarche = new BaseService("Marche");
export default function PrixChambre() {
  const [marches, setMarches] = useState([]);
  const [chambres, setChambres] = useState([]);
  const [filterForm, setFilterForm] = useState({
    du: "",
    au: "",
    marche: "tous",
    chambre: "tous",
  });
  const [rows, setRows] = useState([]);
  useEffect(() => {
    serviceMarche.getAll().then((rows) => setMarches(rows));
    service.getAll().then((rows) => setChambres(rows));
  }, []);
  const [form, setForm] = useState({
    du: "",
    au: "",
    marches: [],
    prixChambres: [],
  });
  const generateRepartitions = (e) => {
    e.preventDefault();
    service.getAll().then((rows) => {
      let prixChambres = [];
      rows.forEach((row) => {
        row.repartition_hebergements.forEach((rep) => {
          form.marches.forEach((m) => {
            prixChambres.push({
              appliquer: true,
              lpd: 0,
              dp: 0,
              dp_plus: 0,
              pc: 0,
              pc_plus: 0,
              ai_soft: 0,
              ai: 0,
              ultra_ai: 0,
              id_repartition: rep.id,
              date: "",
              chambre: row.categorie,
              ad: rep.nb_adulte,
              enf: rep.nb_enfant,
              id_marche: m.id,
              marche: m.label,
            });
          });
        });
      });
      setForm({ ...form, prixChambres });
    });
  };

  const handleMarche = (checked, marche) => {
    if (!checked) {
      setForm({
        ...form,
        marches: form.marches.filter((m) => m.id !== marche.id),
      });
    } else {
      setForm({ ...form, marches: [...form.marches, marche] });
    }
  };

  const handlePrice = (field, rep, value) => {
    let prixChambres = [...form.prixChambres];
    let repIndex = prixChambres.findIndex(
      (p) =>
        p.id_marche === rep.id_marche && p.id_repartition === rep.id_repartition
    );
    prixChambres[repIndex][field] = value;
    setForm({ ...form, prixChambres });
  };

  const toggleAppliquer = (checked) => {
    setForm({
      ...form,
      prixChambres: form.prixChambres.map((pc) => {
        return { ...pc, appliquer: checked };
      }),
    });
  };

  const enregistrerPrix = () => {
    let prixWS = [];
    for (
      let date = new moment(form.du);
      date <= new moment(form.au);
      date.add(1, "days")
    ) {
      form.prixChambres
        .filter((pc) => pc.appliquer)
        .forEach((p) => {
          const obj = (({
            lpd,
            dp,
            dp_plus,
            pc,
            pc_plus,
            ai_soft,
            ai,
            ultra_ai,
            id_marche,
            id_repartition,
          }) => ({
            lpd,
            dp,
            dp_plus,
            pc,
            pc_plus,
            ai_soft,
            ai,
            ultra_ai,
            id_marche,
            id_repartition,
          }))(p);
          obj.date = moment(date).format("YYYY-MM-DD");
          prixWS.push(obj);
        });
    }
    service.savePirx(prixWS).then((res) => console.log("res save prix: ", res));
  };

  const filter = (e) => {
    e.preventDefault();
    service
      .getFilteredPrices(filterForm)
      .then((prices) => setRows(prices))
      .catch((error) => console.log(error));
  };

  const updatePrixRow = (id, field, value) => {
    let rowsWU = [...rows];
    const index = rowsWU.findIndex((r) => r.id === id);
    rowsWU[index][field] = value;
    setRows(rowsWU);
  };

  const savePrix = () => {
    service.savePirx(rows).then((res) => console.log("res save prix: ", res));
  };
  return (
    <>
      <div className={"container-fluid"}>
        <h3>
          Prix chambres{" "}
          <button
            type="button"
            className="p-20 btn btn-primary btn-sm"
            data-toggle="modal"
            data-target="#form-modal"
          >
            Gérer
          </button>
        </h3>
        <form onSubmit={filter} className={"row"}>
          <div className="form-group col-12 col-md-2">
            <label htmlFor="">du</label>
            <input
              required
              value={filterForm.du}
              onChange={(e) =>
                setFilterForm({
                  ...filterForm,
                  du: moment(e.target.value).format("YYYY-MM-DD"),
                })
              }
              className={"form-control"}
              type="date"
            />
          </div>
          <div className="form-group col-12 col-md-2">
            <label htmlFor="">Au</label>
            <input
              required
              value={filterForm.au}
              onChange={(e) =>
                setFilterForm({
                  ...filterForm,
                  au: moment(e.target.value).format("YYYY-MM-DD"),
                })
              }
              className={"form-control"}
              type="date"
            />
          </div>
          <div className="form-group col-12 col-md-2">
            <label htmlFor="">Marché</label>
            <select
              value={filterForm.marche}
              onChange={(e) =>
                setFilterForm({ ...filterForm, marche: e.target.value })
              }
              className={"form-control"}
              name=""
              id=""
            >
              <option value="tous">Tous</option>
              {marches.map((m) => (
                <option key={m.id} value={m.id}>
                  {m.label}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-12 col-md-2">
            <label htmlFor="">Chambre</label>
            <select
              value={filterForm.chambre}
              onChange={(e) =>
                setFilterForm({ ...filterForm, chambre: e.target.value })
              }
              className={"form-control"}
              name=""
              id=""
            >
              <option value="tous">Tous</option>
              {chambres.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.categorie}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-12 col-md-2 d-flex align-items-end">
            <button type={"submit"} className={"p-20 btn btn-primary"}>
              Filtrer
            </button>
          </div>
        </form>
        <div className="fixed-header-table-container">
          <table
            className={"table table-striped table-bordered table-prix-chambre"}
          >
            <thead className={"table-dark sticky-header"}>
              <tr>
                <th>Date</th>
                <th>Marché</th>
                <th>Chambre</th>
                <th>Répartition</th>
                <th>lpd</th>
                <th>dp</th>
                <th>dp_plus</th>
                <th>pc</th>
                <th>pc_plus</th>
                <th>ai_soft</th>
                <th>ai</th>
                <th>ultra_ai</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((r) => (
                <tr key={r.id}>
                  <td>{moment(r.date).format("DD/MM/YYYY")}</td>
                  <td>{marches.find((m) => m.id == r.id_marche).label}</td>
                  <td>{r.categorie}</td>
                  <td>{r.repartition}</td>
                  <td>
                    <input
                      onClick={(e) => e.target.select()}
                      type="number"
                      step={0.01}
                      value={r.lpd}
                      onChange={(e) =>
                        updatePrixRow(r.id, "lpd", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      onClick={(e) => e.target.select()}
                      type="number"
                      step={0.01}
                      value={r.dp}
                      onChange={(e) =>
                        updatePrixRow(r.id, "dp", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      onClick={(e) => e.target.select()}
                      type="number"
                      step={0.01}
                      value={r.dp_plus}
                      onChange={(e) =>
                        updatePrixRow(r.id, "dp_plus", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      onClick={(e) => e.target.select()}
                      type="number"
                      step={0.01}
                      value={r.pc}
                      onChange={(e) =>
                        updatePrixRow(r.id, "pc", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      onClick={(e) => e.target.select()}
                      type="number"
                      step={0.01}
                      value={r.pc_plus}
                      onChange={(e) =>
                        updatePrixRow(r.id, "pc_plus", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      onClick={(e) => e.target.select()}
                      type="number"
                      step={0.01}
                      value={r.ai_soft}
                      onChange={(e) =>
                        updatePrixRow(r.id, "ai_soft", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      onClick={(e) => e.target.select()}
                      type="number"
                      step={0.01}
                      value={r.ai}
                      onChange={(e) =>
                        updatePrixRow(r.id, "ai", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      onClick={(e) => e.target.select()}
                      type="number"
                      step={0.01}
                      value={r.ultra_ai}
                      onChange={(e) =>
                        updatePrixRow(r.id, "ultra_ai", e.target.value)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          onClick={savePrix}
          className={"btn btn-primary mt-1 p-20"}
          style={{ float: "right" }}
        >
          Enregistrer
        </button>
      </div>

      <div
        className="modal fade modal-lg"
        id="form-modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-mw">
          <div className="modal-content" style={{ width: "fit-content" }}>
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Formulaire prix
              </h1>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={generateRepartitions}>
                du:{" "}
                <input
                  required
                  type="date"
                  value={form.du}
                  onChange={(e) => setForm({ ...form, du: e.target.value })}
                />
                au:{" "}
                <input
                  required
                  type="date"
                  value={form.au}
                  onChange={(e) => setForm({ ...form, au: e.target.value })}
                />
                {marches.map((m) => (
                  <span style={{ marginLeft: 4 }} key={m.id}>
                    <input
                      type="checkbox"
                      onChange={(e) => handleMarche(e.target.checked, m)}
                    />
                    {m.label}
                  </span>
                ))}
                <button className={"btn btn-primary"}>generer</button>
              </form>

              <table
                className={
                  "table table-stripped table-bordered mt-2 tableFixHead table-prix-chambre"
                }
              >
                <thead className={"table-dark"}>
                  <tr>
                    <th>
                      <div className={"d-flex align-items-center"}>
                        <input
                          type="checkbox"
                          onChange={(e) => toggleAppliquer(e.target.checked)}
                        />
                        <span>Appliquer</span>
                      </div>
                    </th>
                    <th>chambre</th>
                    <th>ad</th>
                    <th>enf</th>
                    <th>Marché</th>
                    <th>lpd</th>
                    <th>dp</th>
                    <th>dp_plus</th>
                    <th>pc</th>
                    <th>pc_plus</th>
                    <th>ai_soft</th>
                    <th>ai</th>
                    <th>ultra_ai</th>
                  </tr>
                </thead>
                <tbody>
                  {form.prixChambres.map((p, i) => {
                    const prev = form.prixChambres[i - 1] || {};
                    return (
                      <tr
                        className={"prix-chambre-tr"}
                        key={i}
                        style={{
                          borderTop:
                            p.chambre !== prev.chambre ? "2px solid" : "",
                        }}
                      >
                        <td>
                          <input
                            type="checkbox"
                            checked={p.appliquer}
                            onChange={(e) =>
                              handlePrice("appliquer", p, e.target.checked)
                            }
                          />
                        </td>
                        <td>{p.chambre}</td>
                        <td>{p.ad}</td>
                        <td>{p.enf}</td>
                        <td>{p.marche}</td>
                        <td>
                          <input
                            type={"number"}
                            onClick={(e) => e.target.select()}
                            value={p.lpd}
                            step={0.001}
                            onChange={(e) =>
                              handlePrice("lpd", p, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type={"number"}
                            onClick={(e) => e.target.select()}
                            value={p.dp}
                            step={0.001}
                            onChange={(e) =>
                              handlePrice("dp", p, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type={"number"}
                            onClick={(e) => e.target.select()}
                            value={p.dp_plus}
                            step={0.001}
                            onChange={(e) =>
                              handlePrice("dp_plus", p, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type={"number"}
                            onClick={(e) => e.target.select()}
                            value={p.pc}
                            step={0.001}
                            onChange={(e) =>
                              handlePrice("pc", p, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type={"number"}
                            onClick={(e) => e.target.select()}
                            value={p.pc_plus}
                            step={0.001}
                            onChange={(e) =>
                              handlePrice("pc_plus", p, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type={"number"}
                            onClick={(e) => e.target.select()}
                            value={p.ai_soft}
                            step={0.001}
                            onChange={(e) =>
                              handlePrice("ai_soft", p, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type={"number"}
                            onClick={(e) => e.target.select()}
                            value={p.ai}
                            step={0.001}
                            onChange={(e) =>
                              handlePrice("ai", p, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type={"number"}
                            onClick={(e) => e.target.select()}
                            value={p.ultra_ai}
                            step={0.001}
                            onChange={(e) =>
                              handlePrice("ultra_ai", p, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={enregistrerPrix}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
