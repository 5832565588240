import { Fragment, useEffect } from "react";
import AdminPageTitle from "../../../components/admin-page-title";
import FormEvenement from "./form";
import LoadingComponent from "../../../components/loading-component";
import { useDispatch, useSelector } from "react-redux";
import { evenementActions, evenementStateSelector } from "../../../app/slice/evenement-slice";
import ErrorComponent from "../../../components/error-component";
import evenementThunk from "../../../app/thunk/evenement-thunk";
import ListeEvenements from "./liste";
import BaseService from "../../../service/base.service";
const chambresService = new BaseService("Hebergement")
const SectionTitle = ({ title }) => (
  <h3 className="bg-dark text-center text-light">{title}</h3>
);

export default function GestionEvenements() {
  const {loading, error} = useSelector(evenementStateSelector)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(evenementThunk.getAll())
    chambresService
    .getAll()
    .then(chambres => dispatch(evenementActions.setState({chambres: chambres.map(c => ({id: c.id, categorie: c.categorie, valeurs: []}))})))
  }, [])
  return (
    <Fragment>
      <AdminPageTitle title={"Gestion des événements"} />

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 border-right">
            <SectionTitle title={"Forumulaire événement"} />

            <FormEvenement />
          </div>
          <div className="col-12 col-md-6">
            <SectionTitle title={"Liste des événements"} />
            <ListeEvenements/>
          </div>
        </div>
      </div>

      {
        loading && <LoadingComponent/>
      }
      {
        error && <ErrorComponent close={() => dispatch(evenementActions.setState({error: null}))} message={error}/>
      }
    </Fragment>
  );
}
