import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL_V2 } from "../config/constants";
import { BiArrowBack } from "react-icons/bi";

export default function PaymentPolicy() {
    const [data, setData] = useState("");
    useEffect(() => {
        axios
            .get(API_URL_V2 + "conditions-paiement")
            .then((res) => setData(res.data.conditions_paiement));
    }, [])
    if(!data) return <>Loading...</>
    return (
        <div className="container">
            <img width={150} src="assets/images/logo.jpg" alt="" />
            <div dangerouslySetInnerHTML={{__html: data}} />

            <button onClick={() => window.close()} className="btn btn-success mb-5"><BiArrowBack/>Back to reservation page</button>
        </div>
    )
}