import ReactMapboxGl, { Layer, Feature, Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FaMapMarkerAlt } from "react-icons/fa";

const Map = ReactMapboxGl({
    accessToken:
        'pk.eyJ1IjoiYWJpZHNhaWZlZGRpbmUiLCJhIjoiY2x4d3pjbjkzMTdjNTJqc2F6ZDViaHhreSJ9.5Z7BlImB24E6Gokrk1SbGg'
});


const coordinates = [10.568, 36.401]
export default function ContactMap() {
    return (
        <Map

            style="mapbox://styles/abidsaifeddine/clxx34qtg00zc01qwgo92grzq"
            containerStyle={{
                height: '500px',
                width: '100vw'
            }}
            center={coordinates}
            zoom={[12]}
        >
            <Marker
                style={{ cursor: "pointer" }}
                onClick={() => window.open("https://www.google.com/maps/dir//Hammamet+Garden,+119+BP,+Hammamet/@36.4009556,10.5649713,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x12fd61fc0da7e8ef:0x585d4ef88350afa8!2m2!1d10.5674276!2d36.4011202!3e0?entry=ttu", "_blank")}
                coordinates={coordinates}
                anchor="bottom">
                <FaMapMarkerAlt style={{ color: "blue" }} size={30} />
            </Marker>
        </Map>
    )
}