import { createAsyncThunk } from "@reduxjs/toolkit"
import BaseService from "../../service/base.service"

const evenementThunk = {}
const resource = "evenements"
const api = new BaseService("Evenement")
evenementThunk.getAll = createAsyncThunk(`${resource}-get-all`, async (_, ThunkAPI) => {
    try {
        return await api.getAll()
    } catch (error) {
        return ThunkAPI.rejectWithValue(error.message)
    }
})
evenementThunk.create = createAsyncThunk(`${resource}-create`, async (data, ThunkAPI) => {
    try {
        return await api.create(data)
    } catch (error) {
        return ThunkAPI.rejectWithValue(error.response.data)
    }
})
evenementThunk.save = createAsyncThunk(`${resource}-save`, async (data, ThunkAPI) => {
    try {
        return await api.save(data.id, data)
    } catch (error) {
        return ThunkAPI.rejectWithValue(error.message)
    }
})
evenementThunk.delete = createAsyncThunk(`${resource}-delete`, async (id, ThunkAPI) => {
    try {
        const resDelete = await api.delete(id)
        return resDelete?.row === 1 ? id : 0
    } catch (error) {
        return ThunkAPI.rejectWithValue(error.message)
    }
})
evenementThunk.get = createAsyncThunk(`${resource}-get`, async (id, ThunkAPI) => {
    try {
        return await api.findBy("id",id).then(res => res[0])
    } catch (error) {
        return ThunkAPI.rejectWithValue(error.message)
    }
})

export default evenementThunk