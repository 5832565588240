import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  result: false,
  reservations: [],
  dateRange: { du: "", au: "" },
  selectedCurrency: "TND",
  currency: "TND",
  repartition: { nb_chambres: 1, nb_adultes: 0, nb_enfants: 0, nb_bebes: 0 },
  roomsForms: [{ id: 1, nb_adultes: 0, nb_enfants: 0 }],
  detailsReservation: {
    du: "",
    au: "",
    nb_nuits: 0,
    nb_adultes: 0,
    nb_enfants: 0,
    nb_bebes: 0,
    nb_chambres: 0,
  },
  conditionsPaiements: null,
};

export const searchResultSlice = createSlice({
  name: "search-result",
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.loading = true;
    },
    setResult: (state, action) => {
      state.result = action.payload.availableRooms;
      state.conditionsPaiements = action.payload.conditions_paiements;
      state.currency = action.payload.selectedCurrency;
      if (action.payload.detailsReservation)
        state.detailsReservation = action.payload.detailsReservation;
      state.loading = false;
    },
    setReservation: (state, action) => {
      state.reservations = action.payload;
    },
    setSelectedArrangement: (state, action) => {
      const { roomIndex, arrangement } = action.payload;
      state.result[roomIndex].selectedArrangement = arrangement;
    },
    setDate: (state, action) => {
      state.dateRange = action.payload;
    },
    setRepartition: (state, action) => {
      state.repartition = action.payload;
    },
    setSelectedHebergement: (state, action) => {
      const { roomIndex, hebergement } = action.payload;
      state.result[roomIndex].selectedHebergement = hebergement;
    },
    setRoomsForms: (state, action) => {
      state.roomsForms = action.payload;
    },
    setSelectedCurrency: (state, { payload }) => {
      state.selectedCurrency = payload;
    },
  },
});

export const searchResultActions = searchResultSlice.actions;
export const searchResultSelector = (state) => state.searchResult;
export default searchResultSlice.reducer;
