import {useEffect, useState} from "react";
import BaseService from "../service/base.service";

const service = new BaseService("Marche");
export default function SelectMarche({value, onMarcheSelected}){
    const [rows, setRows] = useState([]);
    useEffect(() => {
        service
            .getAll()
            .then(res => setRows(res))
    }, [])
    return (
        <div className={"form-group"}>
            <label>Marché</label>
            <select className={"form-control"} value={value} onChange={e => onMarcheSelected(e.target.value)}>
                <option value="0">Veuillez selectionner</option>
                {
                    rows.map(r => (
                        <option key={r.id} value={r.id}>{r.label}</option>
                    ))
                }
            </select>
        </div>
    )
}
