import { useEffect, useState } from "react";
import { BiEdit, BiImage, BiSave, BiTrash } from "react-icons/bi";
import BaseService from "../../../service/base.service";
import moment from "moment";
import FileUploader from "../../../components/file-uploader";

const service = new BaseService("Label");
const FORM_IS = {
  type: "",
  label: "",
  date_debut_sejour: "",
  date_fin_sejour: "",
  date_debut_reservation: "",
  date_fin_reservation: "",
  icon_url: "",
};
export default function GestionLabels() {
  const [rows, setRows] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState(FORM_IS);
  const [filterForm, setFilterForm] = useState({
    etiquette: true,
    addedValue: true,
    sousCategorie: true,
  });
  const getAll = () => {
    setLoading(true);
    service
      .getAll()
      .then((rows) => setRows(rows))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getAll();
  }, []);

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onUpdateClicked = (object) => {
    setForm(object);
  };

  const onDeleteClicked = (id) => {
    setLoading(true);
    service
      .delete(id)
      .then((res) => getAll())
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    const submitAction = form.id
      ? service.save(form.id, form)
      : service.create(form);
    submitAction
      .then((_) => getAll())
      .catch((error) => setError(error))
      .finally(() => {
        setLoading(false);
        setForm(FORM_IS);
      });
  };
  if (error) return <>{JSON.stringify(error)}</>;
  if (!rows || loading) return <>chargement...</>;
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-3 border-right">
          <h3>Gestion des labels</h3>

          <form onSubmit={submit}>
            <div className="form-group">
              <label htmlFor="select-type">Type</label>
              <select
                required
                value={form.type}
                onChange={handleChangeForm}
                name="type"
                id="select-type"
                className="form-control"
              >
                <option value="">-- Veuillez selectionner --</option>
                <option value="etiquette">étiquette</option>
                <option value="addedvalue">added value</option>
                <option value="souscategorie">sous catégorie</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="input-label">Libellé</label>
              <input
                required
                value={form.label}
                onChange={handleChangeForm}
                name="label"
                type="text"
                className="form-control"
                id="input-label"
              />
            </div>
            {form.type === "etiquette" && (
              <>
                <div className="form-group">
                  <label htmlFor="input-date-debut-sejour">
                    Date début séjour
                  </label>
                  <input
                    value={form.date_debut_sejour}
                    onChange={handleChangeForm}
                    name="date_debut_sejour"
                    type="date"
                    className="form-control"
                    id="input-date-debut-sejour"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="input-date-fin-sejour">Date fin séjour</label>
                  <input
                    value={form.date_fin_sejour}
                    onChange={handleChangeForm}
                    name="date_fin_sejour"
                    type="date"
                    className="form-control"
                    id="input-date-fin-sejour"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="input-date-debut-reservation">
                    Date début réservation
                  </label>
                  <input
                    value={form.date_debut_reservation}
                    onChange={handleChangeForm}
                    name="date_debut_reservation"
                    type="date"
                    className="form-control"
                    id="input-date-debut-reservation"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="input-date-fin-reservation">Date fin réservation</label>
                  <input
                    value={form.date_fin_reservation}
                    onChange={handleChangeForm}
                    name="date_fin_reservation"
                    type="date"
                    className="form-control"
                    id="input-date-fin-reservation"
                  />
                </div>
              </>
            )}
            <FileUploader
              id="file-uploader"
              onUrlReceived={(url) => setForm({ ...form, icon_url: url })}
              html={
                <label
                  style={{
                    width: 80,
                    height: 80,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    color: "black",
                    border: "1px solid lightgray",
                    marginBottom: 16,
                  }}
                  htmlFor="file-uploader"
                  className="btn btn-success"
                >
                  <BiImage />
                  {form.icon_url && (
                    <img
                      src={form.icon_url}
                      alt="icone"
                      height={40}
                      width={40}
                    />
                  )}
                </label>
              }
            />
            <button>
              <BiSave />
              Enregistrer
            </button>
          </form>
        </div>
        <div className="col-12 col-md-9">
          <div className="form-inline mb-2">
            <div className="form-group">
              <input
                checked={filterForm.etiquette}
                onChange={(e) =>
                  setFilterForm({ ...filterForm, etiquette: e.target.checked })
                }
                type="checkbox"
                id="checkbox-etiquette"
              />
              <label htmlFor="checkbox-etiquette">Étiquette</label>
            </div>
            <div className="form-group ml-2">
              <input
                checked={filterForm.addedValue}
                onChange={(e) =>
                  setFilterForm({ ...filterForm, addedValue: e.target.checked })
                }
                type="checkbox"
                id="checkbox-added-value"
              />
              <label htmlFor="checkbox-added-value">Added value</label>
            </div>
            <div className="form-group ml-2">
              <input
                checked={filterForm.sousCategorie}
                onChange={(e) =>
                  setFilterForm({
                    ...filterForm,
                    sousCategorie: e.target.checked,
                  })
                }
                type="checkbox"
                id="checkbox-sous-categorie"
              />
              <label htmlFor="checkbox-sous-categorie">Sous catégorie</label>
            </div>
          </div>

          <table className="table table-sm table-bordered table-striped table-hover">
            <thead className="table-dark">
              <tr>
                <th>#</th>
                <th>icone</th>
                <th>Libéllé</th>
                <th>Dates séjour</th>
                <th>Dates réservation</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows.length === 0 ? (
                <tr>
                  <td align="center" colSpan={"100%"}>
                    Pas de données
                  </td>
                </tr>
              ) : (
                rows
                  .filter((r) => {
                    const inclues = [];
                    if (filterForm.etiquette) inclues.push("etiquette");
                    if (filterForm.addedValue) inclues.push("addedvalue");
                    if (filterForm.sousCategorie) inclues.push("souscategorie");
                    return inclues.includes(r.type);
                  })
                  .map((r, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>

                      <td>
                        <img
                          src={r.icon_url}
                          alt="icon"
                          height={40}
                          width={40}
                        />
                      </td>
                      <td>{r.label}</td>
                      <td>
                        <ul>
                          <li>
                          {r.date_debut_sejour !== "0000-00-00" &&
                          moment(r.date_debut_sejour).format("DD/MM/YYYY")}
                          </li>
                          <li>
                          {r.date_fin_sejour !== "0000-00-00" &&
                          moment(r.date_fin_sejour).format("DD/MM/YYYY")}
                          </li>
                        </ul>
                      </td>
                      <td>
                      <ul>
                          <li>
                          {r.date_debut_reservation !== "0000-00-00" &&
                          moment(r.date_debut_reservation).format("DD/MM/YYYY")}
                          </li>
                          <li>
                          {r.date_fin_reservation !== "0000-00-00" &&
                          moment(r.date_fin_reservation).format("DD/MM/YYYY")}
                          </li>
                        </ul>
                      </td>
                      <td>{r.type}</td>
                      <td>
                        <button onClick={() => onUpdateClicked(r)}>
                          <BiEdit />
                        </button>
                        <button
                          className="btn-danger ml-3"
                          onClick={() => onDeleteClicked(r.id)}
                        >
                          <BiTrash />
                        </button>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
