import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    landingData: null
}

const slice = createSlice({
    name: "client",
    initialState,
    reducers: {
        setLandingData: (state, {payload}) => {
            state.landingData = payload
        }
    }
})

export const clientActions = slice.actions
export const clientSelector = state => state.client
export default slice.reducer