import axios from "axios";
import { UPLOAD_URL } from "../config/constants";

class UploadFilesService {
    upload(file, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);

        return axios.post(UPLOAD_URL, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "x-auth-token": localStorage.getItem('token')
            },
            onUploadProgress,
        });
    }

}
const instance =  new UploadFilesService()
export default instance;
