import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PromotionService from "../../../../service/promotion.service";
import { formatDateFr } from "../../../../config/constants";
import { BsCircleFill } from "react-icons/bs";

const service = new PromotionService();
export default function ListePromotions() {
  const [rows, setRows] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const getAll = () => {
    service
      .getAll()
      .then((res) => setRows(res))
      .catch((error) => setError(error));
  };
  useEffect(() => {
    getAll();
  }, []);
  const deletePromotion = (id) => {
    setLoading(true)
    service
      .delete(id)
      .then((_) => getAll())
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
      ;
  };

  const updatePromotion = o => {
    service
    .save(o.id, o)
    .then(res => {
      const index = rows.findIndex(r => r.id === o.id)
      if(index !== -1)
        {
          let newArray = [...rows]
          newArray[index].etat = res.etat
          setRows(newArray)
        }
    })
  }

  if (!rows || loading) return <>Chargement...</>;
  if (error) return <>{JSON.stringify(error)}</>;
  return (
    <div>
      <h4 className="border-bottom">Liste des promotions</h4>

      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover table-sm">
          <thead className="table-dark">
            <tr>
              <th>#</th>
              <th>Libellé</th>
              <th>Dates Séjour</th>
              <th>Dates Réservation</th>
              <th>Stays</th>
              <th>État</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.length === 0 ? (
              <tr>
                <td align="center" colSpan={"100%"}>
                  Pas de promotions. <Link to={"/admin/conditions-chambres/promo/form"}>+ Créer</Link>
                </td>
              </tr>
            ) : (
              rows.map((r, index) => (
                <tr key={r.id}>
                  <td>{index + 1}</td>
                  <td>{r.label}</td>
                  <td>
                    <ul>
                      <li>Début: {formatDateFr(r.date_debut_sejour)}</li>
                      <li>Fin: {formatDateFr(r.date_fin_sejour)}</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Début: {formatDateFr(r.date_debut_reservation)}</li>
                      <li>Fin: {formatDateFr(r.date_fin_reservation)}</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li><strong>Min: </strong> <span>{r.min_stay}</span></li>
                      <li><strong>Max: </strong> <span>{r.max_stay}</span></li>
                    </ul>
                  </td>
                  <td align="center">
                    <BsCircleFill style={{color: r.etat ? "green" : "red"}}/>
                  </td>
                  <td>
                    <Link className="mr-3" to={"/admin/conditions-chambres/promo/form/" + r.id}>Modif.</Link>
                    <Link className="mr-3" onClick={() => deletePromotion(r.id)}>Supp.</Link>
                    <Link onClick={() => updatePromotion({id: r.id, etat: !r.etat})}>{r.etat ? "désactiver" : "activer"}</Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
