import { useEffect, useState } from "react";
import BaseService from "../../../service/base.service";
import moment from "moment";
import { BiSave } from "react-icons/bi";
import LoadingComponent from "../../../components/loading-component";
const service = new BaseService("ConditionChambre");

const FORM_IS = {
  id: "",
  label: "",
  du: "",
  au: "",
  reduction_3lit: "",
  reduction_4lit: "",
  reduction_2ad_enf1: "",
  reduction_2ad_enf2: "",
  reduction_1ad_enf1: "",
  reduction_1ad_enf2: "",
  reduction_1ad_enf3: "",
  reduction_0ad_enf1: "",
  reduction_0ad_enf2: "",
  reduction_0ad_enf3: "",
  reduction_0ad_enf4: "",
  reduction_enfant_single: "",
  supplement_single_obligatoire: false,
  min_age_enf: "",
  max_age_enf: "",
  min_age_bebe: "",
  max_age_bebe: "",
};
export default function Conditions() {
  const [rows, setRows] = useState(null);
  const [form, setForm] = useState(FORM_IS);
  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const getAll = () => {
    service
      .getAll()
      .then((res) => setRows(res.filter((r) => !r.id_promotion)))
      .catch((error) => console.error(error));
  };
  const submit = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    const saveAction =
      form.id !== ""
        ? service.save(form.id, form)
        : service.create({ ...form, type: "cc" });

    saveAction
      .then(() => {
        getAll();
        closeForm();
      })
      .finally(() => setFormLoading(false));
  };

  const deleteCondition = (id) => {
    setLoading(true);
    service
      .delete(id)
      .then(() => {
        getAll();
        closeForm();
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getAll();
  }, []);

  const openForm = () => {
    document.getElementById("button-open-modal-update").click();
  };
  const closeForm = () => {
    document.getElementById("button-close-modal").click();
  };

  const onUpdateClicked = (row) => {
    setForm(row);
    openForm();
  };

  const setFormField = (e) => {
    const { name, value, checked } = e.target;
    setForm({ ...form, [name]: checked || value });
  };
  if (!rows) return <p>Chargement...</p>;
  return (
    <div className={"row"}>
      <div className="col-12 d-flex align-items-center justify-content-between">
        <h3>Conditions chambres</h3>
        <button
          onClick={() => setForm(FORM_IS)}
          id="button-open-modal"
          data-toggle="modal"
          data-target="#form"
          className="btn btn-primary btn-sm"
        >
          Ajouter
        </button>
        <button
          id="button-open-modal-update"
          data-toggle="modal"
          data-target="#form"
          hidden
        />
      </div>
      <div className="col-12 table-responsive mt-3">
        <table className="table table-sm table-hover table-bordered table-striped">
          <thead className="table-dark">
            <tr>
              <th>#</th>
              <th>Label</th>
              <th>Du</th>
              <th>Au</th>
              <th>Réd 3ème Lit</th>
              <th>Réd 4ème Lit</th>
              <th>Réd 2ad + Enf</th>
              <th>Réd 1ad + Enf</th>
              <th>Réd 0ad + Enf</th>
              <th>Sing.Obl</th>
              <th>Age bébé</th>
              <th>Age enf</th>
              
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.length === 0 ? (
              <tr>
                <td align="center" colSpan={"100%"}>
                  Pas de données
                </td>
              </tr>
            ) : (
              rows.map((r, index) => (
                <tr key={r.id}>
                  <td>{index + 1}</td>
                  <td>{r.label}</td>
                  <td>{moment(r.du).format("DD / MM / YYYY")}</td>
                  <td>{moment(r.au).format("DD / MM / YYYY")}</td>
                  <td>{r.reduction_3lit} %</td>
                  <td>{r.reduction_4lit} %</td>
                  <td>
                    <ul>
                      <li>enf1: {r.reduction_2ad_enf1} %</li>
                      <li>enf2: {r.reduction_2ad_enf2} %</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>enf1: {r.reduction_1ad_enf1} %</li>
                      <li>enf2: {r.reduction_1ad_enf2} %</li>
                      <li>enf3: {r.reduction_1ad_enf3} %</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>enf1: {r.reduction_0ad_enf1} %</li>
                      <li>enf2: {r.reduction_0ad_enf2} %</li>
                      <li>enf3: {r.reduction_0ad_enf3} %</li>
                      <li>enf4: {r.reduction_0ad_enf4} %</li>
                    </ul>
                  </td>
                  <td
                    style={{
                      color: r.supplement_single_obligatoire ? "green" : "red",
                    }}
                  >
                    {r.supplement_single_obligatoire ? "oui" : "non"}
                  </td>

                  <td style={{ wordBreak: "keep-all" }}>
                    min: {r.min_age_bebe} max: {r.max_age_bebe}
                  </td>

                  <td style={{ wordBreak: "keep-all" }}>
                    min: {r.min_age_enf} max: {r.max_age_enf}
                  </td>
                 
                  <td>
                    <button className="btn btn-sm btn-warning" onClick={() => onUpdateClicked(r)}>Modif.</button>
                    <button className="btn btn-sm btn-danger" onClick={() => deleteCondition(r.id)}>Supp.</button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div
        className="modal fade"
        id="form"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal-conditions-chambres">
                Formulaire Conditions chambres
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="button-close-modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <form onSubmit={submit}>
                  <div className="row border-bottom">
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="label">Label</label>
                        <input
                          className="form-control"
                          type="text"
                          id="label"
                          name="label"
                          onChange={setFormField}
                          value={form.label}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="du">Date début</label>
                        <input
                          className="form-control"
                          type="date"
                          id="du"
                          name="du"
                          onChange={setFormField}
                          value={form.du}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="du">Date fin</label>
                        <input
                          className="form-control"
                          type="date"
                          id="au"
                          name="au"
                          onChange={setFormField}
                          value={form.au}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row border-bottom">
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="reduction-3lit">
                          Rédution 3ème Lit
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="reduction-3lit"
                          name="reduction_3lit"
                          onChange={setFormField}
                          value={form.reduction_3lit}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="reduction-4lit">
                          Rédution 4éme Lit
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="reduction-4lit"
                          name="reduction_4lit"
                          onChange={setFormField}
                          value={form.reduction_4lit}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="supp-single-obligatoire">
                          Supp. Single Obligatoire
                        </label>
                        <input
                          className="form-control"
                          type="checkbox"
                          id="supp-single-obligatoire"
                          name="supplement_single_obligatoire"
                          onChange={setFormField}
                          checked={form.supplement_single_obligatoire}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Ages  */}
                  <div className="row border-bottom">
                    <div className="col-12 col-md-3">
                      <div className="form-group">
                        <label htmlFor="min-age-enfant">Min age enfant</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="min-age-enfant"
                          name="min_age_enf"
                          onChange={setFormField}
                          value={form.min_age_enf}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="form-group">
                        <label htmlFor="min-age-enfant">Max age enfant</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="max-age-enfant"
                          name="max_age_enf"
                          onChange={setFormField}
                          value={form.max_age_enf}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="form-group">
                        <label htmlFor="min-age-enfant">Min age bébés</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="min-age-bebe"
                          name="min_age_bebe"
                          onChange={setFormField}
                          value={form.min_age_bebe}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="form-group">
                        <label htmlFor="max-age-enfant">Max age bébés</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="max-age-bebe"
                          name="max_age_bebe"
                          onChange={setFormField}
                          value={form.max_age_bebe}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Réduction 2adultes + enfants */}
                  <div className="row border-bottom align-items-center">
                    <div className="col-12 col-md-4">
                      <small>Réduction 2 Adulte + enfants</small>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="form-group">
                        <label htmlFor="2ad-enf1">1èr Enfant</label>
                        <input
                          className="form-control"
                          type="text"
                          id="2ad-enf1"
                          name="reduction_2ad_enf1"
                          onChange={setFormField}
                          value={form.reduction_2ad_enf1}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="form-group">
                        <label htmlFor="2ad-enf2">2ème enfant</label>
                        <input
                          className="form-control"
                          type="text"
                          id="2ad-enf2"
                          name="reduction_2ad_enf2"
                          onChange={setFormField}
                          value={form.reduction_2ad_enf2}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Réduction 1adulte + enfants */}
                  <div className="row border-bottom align-items-center">
                    <div className="col-12 col-md-4">
                      <small>Réduction 1 Adulte + enfants</small>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="form-group">
                        <label htmlFor="2ad-enf1">1èr Enfant</label>
                        <input
                          className="form-control"
                          type="text"
                          id="1ad-enf1"
                          name="reduction_1ad_enf1"
                          onChange={setFormField}
                          value={form.reduction_1ad_enf1}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="form-group">
                        <label htmlFor="1ad-enf2">2ème enfant</label>
                        <input
                          className="form-control"
                          type="text"
                          id="1ad-enf2"
                          name="reduction_1ad_enf2"
                          onChange={setFormField}
                          value={form.reduction_1ad_enf2}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="form-group">
                        <label htmlFor="1ad-enf3">3ème enfant</label>
                        <input
                          className="form-control"
                          type="text"
                          id="1ad-enf3"
                          name="reduction_1ad_enf3"
                          onChange={setFormField}
                          value={form.reduction_1ad_enf3}
                        />
                      </div>
                    </div>
                  </div>
                  
                  {/* Réduction 0adulte + enfants */}
                  <div className="row border-bottom align-items-center">
                    <div className="col-12 col-md-4">
                      <small>Réduction 0 Adulte + enfants</small>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="form-group">
                        <label htmlFor="0ad-enf1">1èr Enfant</label>
                        <input
                          className="form-control"
                          type="text"
                          id="1ad-enf1"
                          name="reduction_0ad_enf1"
                          onChange={setFormField}
                          value={form.reduction_0ad_enf1}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="form-group">
                        <label htmlFor="0ad-enf2">2ème enfant</label>
                        <input
                          className="form-control"
                          type="text"
                          id="1ad-enf2"
                          name="reduction_0ad_enf2"
                          onChange={setFormField}
                          value={form.reduction_0ad_enf2}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="form-group">
                        <label htmlFor="0ad-enf3">3ème enfant</label>
                        <input
                          className="form-control"
                          type="text"
                          id="1ad-enf3"
                          name="reduction_0ad_enf3"
                          onChange={setFormField}
                          value={form.reduction_0ad_enf3}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="form-group">
                        <label htmlFor="0ad-enf4">4ème enfant</label>
                        <input
                          className="form-control"
                          type="text"
                          id="0ad-enf4"
                          name="reduction_0ad_enf4"
                          onChange={setFormField}
                          value={form.reduction_0ad_enf4}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row border-bottom align-items-center">
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="reduction_enfant_single">
                          Réd% enf. Sing
                        </label>
                        <input
                          id="reduction_enfant_single"
                          name="reduction_enfant_single"
                          value={form.reduction_enfant_single}
                          onChange={setFormField}
                          type="number"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="pt-2 mt-2 text-right">
                    <button className="btn btn-primary" disabled={formLoading}>
                      <BiSave />
                      {formLoading ? "Chargement..." : "Enregistrer"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <LoadingComponent />}
    </div>
  );
}
