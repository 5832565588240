import { BiListOl, BiPlus } from "react-icons/bi";
import { NavLink, Outlet } from "react-router-dom";

export default function GestionPromotions() {
  return (
    <div>
      <div className="border-bottom mb-3 d-flex justify-content-between align-items-center">
        <h3>Gestion des promotions</h3>
        <div>
          <NavLink to={"list"}><BiListOl/>Liste des promotions</NavLink> /
          <NavLink to={"form"}><BiPlus/>Créer une promotion</NavLink>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
