import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiLoader, BiSave } from "react-icons/bi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AdminPageTitle from "../../components/admin-page-title";
import { axiosTokenV2 } from "../../config/http-common";
import { API_URL_V2 } from "../../config/constants";

export default function GestionConditionsPaiement() {
  const [value, setValue] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const save = () => {
    setSubmitting(true);
    axiosTokenV2()
      .post("/save-conditions-paiement", { value })
      .then((res) => {
        if (res.data.affectedRows && res.data.affectedRows > 0) {
          window.location.reload();
        } else {
          console.log(res);
          alert("Erreur! ");
        }
      });
  };

  const get = () => {
    axios
      .get(API_URL_V2 + "conditions-paiement")
      .then((res) => setValue(res.data.conditions_paiement));
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <>
      <AdminPageTitle title={"Gestion de la page conditions de paiements"} />
      <ReactQuill
        style={{ height: 300, marginBottom: 60 }}
        theme="snow"
        value={value}
        onChange={setValue}
      />
      <button
        disabled={submitting}
        onClick={save}
        className="btn btn-success ms-auto"
      >
        {submitting ? <BiLoader/> : <BiSave />}
        {submitting ? "Chargement..." : "Enregistrer"}
      </button>
    </>
  );
}
