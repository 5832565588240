import axios from "axios";
import { API_URL } from "../config/constants";
import BaseService from "./base.service";

class ReservationService extends BaseService {
  constructor() {
    super("ReservationHebergement");
    this.API_URL = API_URL + "reservation/";
  }
  async getHebergements(data) {
    return await axios
      .post(this.API_URL + "recherche", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  }

  async save(data) {
    return await axios
      .post(this.API_URL + "create", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res)
      .catch((error) => error);
  }

  async saveSS(data){
    return axios
    .post(this.API_URL + "create-ss", data, {
      headers:{
        "Content-Type": "application/json"
      }
    })
    .then((res) => res)
    .catch((error) => error);
  }

  async getAll(data) {
    return await axios
      .post(this.API_URL + "get-all", data, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  }

  async getReservationsSS(){
    return await 
    axios.get(this.API_URL + "v2/get-reservations-ss")
    .then(res => res.data)
  } 
  
}


export default ReservationService;
