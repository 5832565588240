import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  BiArrowToLeft,
  BiCalendar,
  BiCheckbox,
  BiCheckboxChecked,
  BiMoon,
} from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  searchResultActions,
  searchResultSelector,
} from "../app/slice/search-result.slice";
import AppModal from "../components/app-modal";
import { API_URL, APP_URL } from "../config/constants";
import SearchForm from "./search-form";
import "moment/locale/fr";
import { NavLink } from "react-router-dom";
moment().locale("fr");
export default function Reservations({ onBackClicked }) {
  const [showForm, setShowForm] = useState(false);
  const [paiementLinkLoading, setPaiementLinkLoading] = useState(false);
  const [country_code, setCountry_code] = useState(null);
  const [clientForm, setClientForm] = useState({
    nom: "",
    email: "",
    tel: "",
    adresse: "",
  });
  const [showModal, setShowModal] = useState(false);
  const searchResultState = useSelector(searchResultSelector);
  const {
    dateRange,
    repartition,
    result: rooms,
    detailsReservation,
    conditionsPaiements,
    currency,
  } = searchResultState;

  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [totaux, setTotaux] = useState({
    total: 0,
    totalPaiementEnLigne: 0,
    totalPaiementReception: 0,
  });
  const [condition_paiement_accepte, setCondition_paiement_accepte] =
    useState(false);
  useEffect(() => {
    fetch("https://geolocation-db.com/json/")
      .then((response) => response.json())
      .then(async (data) => {
        setCountry_code(data.country_code);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (rooms && rooms.length > 0 && conditionsPaiements) {
      const total = rooms
        .map((room, roomIndex) => {
          return { roomIndex, ...room.selectedHebergement };
        })
        .reduce(
          (a, b) => a + b.arrangements[rooms[b.roomIndex].selectedArrangement],
          0
        );
      const totalPaiementEnLigne =
        (total * conditionsPaiements.pourcentage_paiement_enligne) / 100;
      const totalPaiementReception = total - totalPaiementEnLigne;
      setTotaux({ total, totalPaiementEnLigne, totalPaiementReception });
    } else {
      setTotaux({
        total: 0,
        totalPaiementEnLigne: 0,
        totalPaiementReception: 0,
      });
    }
  }, [rooms, conditionsPaiements]);

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    function handleMessage(event) {
      if (event.data === "reloadPage") {
        window.location.reload();
      }
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const submitReservation = async (e) => {
    e.preventDefault();

    setPaiementLinkLoading(true);
    // calcul de la somme après commission
    const total = rooms
      .map((room, roomIndex) => {
        return { roomIndex, ...room.selectedHebergement };
      })
      .reduce(
        (a, b) => a + b.arrangements[rooms[b.roomIndex].selectedArrangement],
        0
      );
    const env = process.env.NODE_ENV;
    const orderId = await axios
      .get(API_URL + "v2/reservation/get-max-voucher-sequence")
      .then((res) => res.data.orderId)
      .catch((error) => error);
    // generer le lien de paiement
    axios
      .post(
        env === "production"
          ? "https://app.paymee.tn/api/v2/payments/create"
          : "https://sandbox.paymee.tn/api/v2/payments/create",
        {
          amount: totaux.totalPaiementEnLigne,
          note: "Paiement de séjour chez Hôtel Hammamet Garden Yasmine Hammamet",
          first_name: clientForm.nom,
          last_name: ".",
          email: clientForm.email,
          phone: clientForm.tel,
          return_url: "https://www.return_url.tn",
          cancel_url: "https://www.cancel_url.tn",
          webhook_url: APP_URL + "confirmation-paiement-reservation",
          order_id: orderId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              env === "production"
                ? "Token c0e78000aefd804d0298a2ee059aea9da815a0e8"
                : "Token 63776b129d3a25313a700b305e5479bd67f0260c",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setPaiementLinkLoading(false);

          // garder les données de la réservation dans la mémoire
          // on paiement success => lien de succès redirection vers lien reservationSuccess
          // envoyer les données de la réservation vers le serveur
          // actualiser la page
          const reservation = {
            ...repartition,
            ...clientForm,
            nb_nuits: detailsReservation.nb_nuits,
            date_reservation: moment().format("YYYY-MM-DD"),
            date_debut_sejour: moment(detailsReservation.du).format(
              "YYYY-MM-DD"
            ),
            date_fin_sejour: moment(detailsReservation.au).format("YYYY-MM-DD"),
            total: totaux.total,
            totaux,
            conditionsPaiements,
            currency,
          };
          const hebergements = rooms.map((room) => {
            const { id, categorie: hebergement } = room.selectedHebergement;
            const { nb_adultes, nb_enfants, nb_bebes } = room.room;
            const arrangement = room.selectedArrangement;
            const prix = room.selectedHebergement.arrangements[arrangement];
            return {
              id,
              hebergement,
              nb_adultes,
              nb_enfants,
              nb_bebes,
              arrangement,
              prix,
            };
          });
          reservation.hebergements = hebergements;
          // console.log(reservation);
          localStorage.setItem("reservation", JSON.stringify(reservation));
          console.log(reservation);
          window.open(res.data.data.payment_url, "_blank");
        } else {
          console.log(res.data);
        }
      })
      .catch((error) => {
        setPaiementLinkLoading(false);

        console.log(error);
        alert(error.response.data.errors[0].message);
      });
  };
  return (
    <main id={"main"}>
      <div id={"rooms-header"}>
        <a href={"/"} style={{ position: "relative" }}>
          <h6 className={"home-link"}>
            <BiArrowToLeft />
            Home
          </h6>
        </a>
        <div className="container-fluid" style={{ position: "absolute" }}>
          <h2 style={{ color: "white" }}>Rooms reservation</h2>
        </div>
      </div>
      <div className={"mt-4"} style={{ marginLeft: 8, marginRight: 8 }}>
        <SearchForm parent={"SearchResult"} />
      </div>
      <div className="container">
        {searchResultState.loading ? (
          <p>Loading . . .</p>
        ) : (
          <div className="row">
            <div className="col-12 col-md-7">
              {searchResultState.result &&
              searchResultState.result.length > 0 ? (
                searchResultState.result.map((room, i) => (
                  <div className={"room-container"} key={i}>
                    <div className="room-container-title">
                      <div className="d-flex justify-content-between">
                        <h4>
                          Room {i + 1}
                          {[...Array(room.room.nb_adultes)].map(
                            (_, index_img) => (
                              <img
                                key={index_img}
                                src="assets/icons/adult.png"
                                alt=""
                              />
                            )
                          )}
                          {[...Array(room.room.nb_enfants)].map(
                            (_, index_img) => (
                              <img
                                key={index_img}
                                src="assets/icons/child.png"
                                alt=""
                              />
                            )
                          )}
                          {[...Array(room.room.nb_bebes)].map(
                            (_, index_img) => (
                              <img
                                key={index_img}
                                src="assets/icons/baby.png"
                                alt=""
                              />
                            )
                          )}
                        </h4>
                        <select
                          value={
                            searchResultState.result[i].selectedArrangement
                          }
                          onChange={(e) =>
                            dispatch(
                              searchResultActions.setSelectedArrangement({
                                roomIndex: i,
                                arrangement: e.target.value,
                              })
                            )
                          }
                        >
                          {room.arrangements.map((a) => (
                            <option key={a} value={a}>
                              {a}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {room.hebergements.length &&
                      room.hebergements.map((h, hi) => {
                        const prix =
                          h.arrangements[
                            searchResultState.result[i].selectedArrangement
                          ];
                        const prix_sans_promotion =
                          h.arrangements_sans_promotion[
                            searchResultState.result[i].selectedArrangement
                          ];
                        const is_promotion = prix_sans_promotion !== prix;
                        return (
                          <div key={hi} className="room row">
                            {/*image*/}
                            <div className="room-img col-12 col-md-5">
                              <div
                                style={{ cursor: "pointer", fontSize: 24 }}
                                onClick={() =>
                                  dispatch(
                                    searchResultActions.setSelectedHebergement({
                                      roomIndex: i,
                                      hebergement: h,
                                    })
                                  )
                                }
                              >
                                {room.selectedHebergement.categorie ===
                                h.categorie ? (
                                  <BiCheckboxChecked />
                                ) : (
                                  <BiCheckbox />
                                )}
                              </div>
                              <img
                                src={h.images[0]?.image}
                                alt=""
                                width={"100%"}
                                height={170}
                              />
                            </div>
                            {/*content*/}
                            <div className="room-content col-12 col-md-5">
                              {/*title*/}
                              <div className="room-title">
                                <h4>{h.categorie}</h4>
                              </div>
                              {/*specs*/}
                              <div className="room-specs mt-2">
                                {h.accessories?.map((ra) => (
                                  <div
                                    key={ra.id}
                                    className="d-flex flex-column justify-content-center align-items-center ml-2"
                                    style={{ flex: 1 }}
                                  >
                                    <img
                                      className=""
                                      height={20}
                                      width={20}
                                      src={ra.image}
                                      alt="ra"
                                    />
                                    <p className="text-center">{ra.label}</p>
                                  </div>
                                ))}
                              </div>
                              {h.added_values.length > 0 && (
                                <div className="added-values">
                                  <h5>Extra: </h5>
                                  <ul>
                                    {h.added_values.map((av) => (
                                      <li
                                        style={{
                                          color: "black",
                                          fontStyle: "italic",
                                          fontWeight: "bold",
                                          textDecoration: "underline",
                                        }}
                                        key={av.id}
                                      >
                                        <img
                                          height={20}
                                          src={av.icon_url}
                                          alt=""
                                          className="mr-1"
                                        />
                                        {av.label}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                            {/*price*/}
                            <div className="room-reservation col-12 col-md-2">
                              {/*price*/}
                              <div className="room-price">
                                <h6
                                  style={{
                                    textDecoration: is_promotion
                                      ? "line-through"
                                      : "",
                                    color: is_promotion ? "red" : "limegreen",
                                  }}
                                >
                                  {
                                    // prix  is defined at the start of the map
                                    Number(prix_sans_promotion).toFixed(3)
                                  }{" "}
                                  {currency}
                                </h6>
                                {is_promotion && (
                                  <h6
                                    style={{
                                      color: "limegreen",
                                      fontSize: "bold",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {
                                      // prix  is defined at the start of the map
                                      Number(prix).toFixed(3)
                                    }{" "}
                                    {currency}
                                  </h6>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ))
              ) : (
                <h3 className={"text-center mt-5"}>No rooms available</h3>
              )}
            </div>
            <div className="col-12 col-md-5">
              <div className="details-reservation">
                <div className="app-card">
                  <div className="app-card-title">
                    <span>Your Reservation</span>
                  </div>
                  <div className="app-card-divs">
                    <div className="app-card-div">
                      <BiCalendar />
                      <div>
                        <strong>
                          {detailsReservation.du !== ""
                            ? moment(detailsReservation.du).format("LL")
                            : "Check-in"}
                        </strong>
                        <p>
                          {detailsReservation.du !== "" &&
                            moment(detailsReservation.du).format("dddd")}
                        </p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <BiCalendar />
                      <div>
                        <strong>
                          {detailsReservation.au !== ""
                            ? moment(detailsReservation.au).format("LL")
                            : "Check-out"}
                        </strong>
                        <p>
                          {detailsReservation.au !== "" &&
                            moment(detailsReservation.au).format("dddd")}
                        </p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <BiMoon />
                      <div>
                        {detailsReservation.nb_nuits}
                        <p>Night(s)</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="app-card">
                  <div className="app-card-title">
                    <span>
                      Distributions{" "}
                      <strong>({detailsReservation.nb_chambres})</strong>{" "}
                      Room(s)
                    </span>
                  </div>
                  <div className="app-card-divs">
                    <div className="app-card-div">
                      <img src="assets/icons/adult.png" alt="" />
                      <div>
                        <strong>{detailsReservation.nb_adultes}</strong>
                        <p>Adults</p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <img src="assets/icons/child.png" alt="" height={20} />
                      <div>
                        <strong>{detailsReservation.nb_enfants}</strong>
                        <p>Children</p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <img src="assets/icons/baby.png" alt="" />
                      <div>
                        <strong>{detailsReservation.nb_bebes}</strong>
                        <p>Babies</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="app-card">
                  <div className="text-right app-card-title border-bottom-0">
                    <h3 className="d-flex"></h3>
                    <h3>
                      <strong>Total Reservation:</strong>{" "}
                      {totaux.total.toFixed(3)} ({currency})
                    </h3>
                    <h3>
                      <strong>
                        Total online payment{" "}
                        {conditionsPaiements
                          ? ` (${conditionsPaiements.pourcentage_paiement_enligne}%)`
                          : ""}
                        :{" "}
                      </strong>{" "}
                      {totaux.totalPaiementEnLigne.toFixed(3)} ({currency})
                    </h3>
                    <h3>
                      <strong>
                        Total payment at reception{" "}
                        {conditionsPaiements
                          ? ` (${
                              100 -
                              conditionsPaiements.pourcentage_paiement_enligne
                            }%)`
                          : ""}
                        :
                      </strong>{" "}
                      {totaux.totalPaiementReception.toFixed(3)} ({currency})
                    </h3>

                    <h5>** Canceling fees:</h5>
                    {conditionsPaiements &&
                    conditionsPaiements.frais_annulations.length === 0 ? (
                      <p
                        style={{ fontWeight: "bold" }}
                        className="text-primary"
                      >
                        Free
                      </p>
                    ) : (
                      <ul>
                        {conditionsPaiements &&
                          conditionsPaiements.frais_annulations.map(
                            (fa, index) => {
                              const date_avant = moment(dateRange.du).subtract(
                                fa.jours_avant,
                                "d"
                              );
                              if (fa.valeur == 0)
                                return (
                                  <p
                                    key={index}
                                    className="mb-0 text-success"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Free before{" "}
                                    {date_avant.format("DD/MM/YYYY")}
                                  </p>
                                );
                              else {
                                const date_0 =
                                  conditionsPaiements.frais_annulations.find(
                                    (fa) => fa.valeur == 0
                                  );
                                const date = moment(dateRange.du).subtract(
                                  date_0.jours_avant,
                                  "d"
                                );
                                return (
                                  <p
                                    className="mb-0 text-danger"
                                    style={{ fontWeight: "bold" }}
                                    key={index}
                                  >
                                    {Number(
                                      totaux.totalPaiementEnLigne *
                                        (fa.valeur / 100)
                                    ).toFixed(3)}{" "}
                                    {currency} After {date.format("DD/MM/YYYY")}
                                  </p>
                                );
                              }
                            }
                          )}
                      </ul>
                    )}
                  </div>
                  <div className="form-group d-flex align-items-center">
                    <input
                    checked={condition_paiement_accepte}
                    onChange={e => setCondition_paiement_accepte(e.target.checked)}
                      type="checkbox"
                      className="form-check"
                      id="payment_policies"
                    />
                    <label className="mb-0 ml-1" htmlFor="payment_policies">
                      I have read and accept the{" "}
                      <NavLink target="_blank" to={"/payment-policy"}>
                        payment policies and conditions
                      </NavLink>
                    </label>
                  </div>
                  <div className="app-card-divs">
                    <button
                      disabled={
                        searchResultState.result.length === 0 ||
                        !searchResultState.result.length ||
                        !condition_paiement_accepte
                      }
                      onClick={() => setShowForm(true)}
                      className="app-button"
                      style={{ width: "100%", paddingTop: 8, paddingBottom: 8 }}
                    >
                      Book my stay
                    </button>
                  </div>
                </div>
                {/*form réservation*/}
                {showForm && (
                  <AppModal
                    modalTitle={"Confirmez votre réservation"}
                    onCloseClicked={() => setShowForm(false)}
                    content={
                      <div className="app-card">
                        <div className="app-card-title">
                          <h5>Please fill this form</h5>
                        </div>
                        <form onSubmit={submitReservation}>
                          <div className="row mt-2">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label htmlFor="name">Name and lastname</label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      nom: e.target.value,
                                    })
                                  }
                                  value={clientForm.nom}
                                  type="text"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label htmlFor="name">Phone number</label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      tel: e.target.value,
                                    })
                                  }
                                  value={clientForm.tel}
                                  type="number"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="name">E-mail</label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      email: e.target.value,
                                    })
                                  }
                                  value={clientForm.email}
                                  type="email"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="name">Address</label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      adresse: e.target.value,
                                    })
                                  }
                                  value={clientForm.adresse}
                                  type="text"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <button
                                disabled={paiementLinkLoading}
                                type={"submit"}
                                className={"app-button w-100 mt-2"}
                                style={{ paddingTop: 8, paddingBottom: 8 }}
                              >
                                {paiementLinkLoading
                                  ? "Loading..."
                                  : "Go To Payment"}
                              </button>
                            </div>
                          </div>
                          {message !== "" && (
                            <p style={{ color: "red" }}>{message}</p>
                          )}
                        </form>
                      </div>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {showModal && (
        <AppModal
          modalTitle={"Message de confirmation"}
          content={
            <div>
              <p>Thank you for choosing our hotel.</p>
              <p>
                Your reservation has been confirmed and we have sent an email
                containing the reservation voucher.
              </p>
              <strong className={"mt-2"}>
                If you have any questions or concerns, please do not hesitate to
                contact us. We look forward to welcoming you soon!
              </strong>
            </div>
          }
          onCloseClicked={() => window.location.reload()}
        />
      )}
    </main>
  );
}
