export default function ErrorComponent({ message, close }) {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0,0,0,.6)",
        zIndex: 9999,
      }}
    >
      <div style={{ padding: 16, background: "white", minWidth: 250, borderRadius: 3 }}>
        <h5>Erreur</h5>
        <p style={{color : "red", fontSize: 12}}>{message}</p>
        <button onClick={close} className="float-right">Ok</button>
      </div>
    </div>
  );
}
