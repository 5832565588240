import { useDispatch, useSelector } from "react-redux";
import { evenementStateSelector } from "../../../app/slice/evenement-slice";
import evenementThunk from "../../../app/thunk/evenement-thunk";
import { formatDateFr } from "../../../config/constants";

export default function ListeEvenements() {
  const { rows } = useSelector(evenementStateSelector);
  const dispatch = useDispatch();
  const deleteRow = (id) => {
    const userConfirmed = window.confirm(
      "Voulez vous vraiment supprimer cet événement?"
    );
    if (userConfirmed) dispatch(evenementThunk.delete(id));
  };
  const onUpdateClicked = (id) => {
    dispatch(evenementThunk.get(id));
  };
  return (
    <div className="table-responsive">
      <table className="table table-sm table-bordered table-striped table-hocer">
        <thead>
          <tr>
            <th>#</th>
            <th>Label</th>
            <th>Dates séjour</th>
            <th>Dates réservation</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {rows.map((r, index) => (
            <tr key={r.id}>
              <td>{index + 1}</td>
              <td>{r.label}</td>
              <td>
                <ul>
                  <li>Du: {formatDateFr(r.date_debut_sejour)}</li>
                  <li>Au: {formatDateFr(r.date_fin_sejour)}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Du: {formatDateFr(r.date_debut_reservation)}</li>
                  <li>Au: {formatDateFr(r.date_fin_reservation)}</li>
                </ul>
              </td>
              <td>
                <button onClick={() => onUpdateClicked(r.id)}>Modif.</button>
                <button onClick={() => deleteRow(r.id)}>Supp.</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
