import { axiosTokenV2 } from "../config/http-common";

export default class ConditionPaiementService {
  constructor() {
    this.entity = "conditions-paiements";
    this.base_url = "conditions-paiements/";
  }

  async getAll() {
    return await axiosTokenV2()
      .get(this.base_url)
      .then((res) => res.data);
  }

  async save(data) {
    return await axiosTokenV2({ "Content-Type": "application/json" })
      .post(this.base_url, data)
      .then((res) => res.data);
  }

  async delete(id) {
    return await axiosTokenV2()
      .delete(this.base_url + id)
      .then((res) => res.data);
  }
}
