import { useEffect, useState } from "react";
import { BiEdit, BiPlus, BiSave, BiTrash } from "react-icons/bi";
import Drawer from "react-modern-drawer";
import AdminPageTitle from "../../../components/admin-page-title";
import ConditionPaiementService from "../../../service/ConditionPaiement.service";
import { formatDateFr } from "../../../config/constants";
const service = new ConditionPaiementService()
const FORM_IS = {
    libelle: "",
    debut_reservation: "",
    fin_reservation: "",
    debut_sejour: "",
    fin_sejour: "",
    pourcentage_paiement_enligne: 0,
    frais_annulations: []
}

export default function ConditionsPaiements() {
    const [form, setForm] = useState(null);
    const [rows, setRows] = useState([]);
    const submit = e => {
        e.preventDefault()
        service.save(form)
            .then(res => {
                console.log(res);
                setForm(null)
                getAll()
            })

    }

    const supprimer = id => {
        const userConfirmed = window.confirm("Confirmer la suppression ?")
        if (userConfirmed)
            service.delete(id)
                .then(res => {
                    console.log(res);
                    getAll()
                })
    }

    const getAll = () => {
        service
            .getAll()
            .then(res => setRows(res))
    }
    useEffect(() => {
        getAll()
    }, [])

    const handleChangeForm = e => {
        const { name, value } = e.target
        setForm({ ...form, [name]: value })
    }

    const ajouterFrais = () => {
        setForm({ ...form, frais_annulations: [...form.frais_annulations, { jours_avant: "", nature: "", valeur: "" }] })
    }

    const supprimerFrais = index => {
        setForm({ ...form, frais_annulations: form.frais_annulations.filter((_, i) => i !== index) })
    }

    const modifierFrais = (index, field, value) => {
        let frais_annulations = [...form.frais_annulations]
        frais_annulations[index][field] = value
        setForm({ ...form, frais_annulations })
    }

    return (
        <>

            <AdminPageTitle title={"Conditions de paiements"} children={<button onClick={() => setForm(FORM_IS)} className="btn btn-primary"><BiPlus />Ajouter</button>} />

            <div className="table-responsive">
                <table className="table table-sm table-hover table-bordered table-striped">
                    <thead className="table-dark">
                        <tr>
                            <th>#</th>
                            <th>Libellé</th>
                            <th>Réservation</th>
                            <th>Séjour</th>
                            <th>%age en ligne</th>
                            <th>Frais d'annulations</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.map((r, index) => (
                                <tr key={r.id}>
                                    <td>{index + 1}</td>
                                    <td>{r.libelle}</td>
                                    <td>
                                        <ul>
                                            <li>{formatDateFr(r.debut_reservation)}</li>
                                            <li>{formatDateFr(r.fin_reservation)}</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>{formatDateFr(r.debut_sejour)}</li>
                                            <li>{formatDateFr(r.fin_sejour)}</li>
                                        </ul>
                                    </td>
                                    <td>
                                        {Number(r.pourcentage_paiement_enligne).toFixed(3)}
                                    </td>
                                    <td>
                                        <ul>
                                            {r.frais_annulations.map(fa => (
                                                <li key={fa.id}>{fa.valeur}{fa.nature === "pourcentage" ? "%" : "DT"} avant :{fa.jours_avant} Jours</li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td>
                                        <button onClick={() => setForm(r)} className="btn btn-warning"><BiEdit /> Mod.</button>
                                        <button onClick={() => supprimer(r.id)} className="btn btn-danger"><BiTrash /> Supp.</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <Drawer
                open={!!form}
                style={{ width: 600, paddingTop: 80, paddingLeft: 16, paddingRight: 16 }}
                onClose={() => setForm(null)}
                direction="right"
            >
                <h3 className="border-bottom">Forumaire conditions de paiements</h3>
                {
                    !form ? <></> : <form onSubmit={submit}>
                        <div className="row">
                            <div className="col-6 form-group">
                                <label htmlFor="libelle">Libelle</label>
                                <input id="libelle" name="libelle" type="text" className="form-control" value={form.libelle} onChange={handleChangeForm} />
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="pourcentage_paiement_enligne">%age paiement en ligne: </label>
                                <input id="pourcentage_paiement_enligne" name="pourcentage_paiement_enligne" type="text" className="form-control" value={form.pourcentage_paiement_enligne} onChange={handleChangeForm} />
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="debut_reservation">Réservation du: </label>
                                <input id="debut_reservation" name="debut_reservation" type="date" className="form-control" value={form.debut_reservation} onChange={handleChangeForm} />
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="fin_reservation">Réservation au: </label>
                                <input id="fin_reservation" name="fin_reservation" type="date" className="form-control" value={form.fin_reservation} onChange={handleChangeForm} />
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="debut_sejour">Séjour du: </label>
                                <input id="debut_sejour" name="debut_sejour" type="date" className="form-control" value={form.debut_sejour} onChange={handleChangeForm} />
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="fin_sejour">Séjour au: </label>
                                <input id="fin_sejour" name="fin_sejour" type="date" className="form-control" value={form.fin_sejour} onChange={handleChangeForm} />
                            </div>

                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <h3>Frais d'annulations</h3>
                                <button type="button" onClick={ajouterFrais} className="btn btn-sm btn-primary"><BiPlus /> Ajouter</button>
                            </div>

                            <div className="col-12 table-responsive" style={{ maxHeight: 300, overflow: "auto" }}>
                                <table className="table-sm table table-hover table-bordered table-striped">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>#</th>
                                            <th>Jours avant</th>
                                            <th>Nature</th>
                                            <th>Valeur</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            form.frais_annulations.map((fa, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <input value={fa.jours_avant} onChange={e => modifierFrais(index, "jours_avant", e.target.value)} type="number" step={1} className="form-control" />
                                                    </td>
                                                    <td>
                                                        <select value={fa.nature} onChange={e => modifierFrais(index, "nature", e.target.value)} className="form-control">
                                                            <option value=""></option>
                                                            <option value="pourcentage">%</option>
                                                            <option value="valeur">Valeur</option>
                                                        </select>
                                                    </td>

                                                    <td>
                                                        <input value={fa.valeur} onChange={e => modifierFrais(index, "valeur", e.target.value)} type="text" className="form-control" />
                                                    </td>
                                                    <td>
                                                        <button onClick={() => supprimerFrais(index)} type="button" className="btn btn-danger"><BiTrash /></button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <button className="btn btn-primary mt-2"><BiSave /> Enregistrer</button>
                    </form>
                }

            </Drawer>
        </>
    )
}