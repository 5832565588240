import {stopSaleActions, stopSaleStateSelector} from "../../../app/slice/stopSale.slice";
import {useDispatch, useSelector} from "react-redux";
import {Fragment, useEffect} from "react";
import StopSaleService from "../../../service/stopSale.service";
import moment from "moment";

const service = new StopSaleService()
export default function StopSale() {
    const {data, form, loading} = useSelector(stopSaleStateSelector)
    const dispatch = useDispatch()
    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        service
            .getData()
            .then(res => dispatch(stopSaleActions.setData(res)))
    }
    const submit = e => {
        dispatch(stopSaleActions.setLoading(true))
        e.preventDefault()
        service
            .saveData(form)
            .then(res => {
                dispatch(stopSaleActions.setLoading(false))
                getData()
                dispatch(stopSaleActions.resetForm())
            })
            .catch(error => console.log(error))
    }

    const deleteStopSale = id => {
        dispatch(stopSaleActions.setLoading(true))
        service
            .delete(id)
            .then(res => {
                {
                    if(res === "success"){
                        dispatch(stopSaleActions.setLoading(false))
                        getData()
                    }
                }
            })
            .catch(error => console.log(error))
    }

    const onEditClicked = stopSale => {
        dispatch(stopSaleActions.populateForm({
            form: "form",
            id: stopSale.id,
            du: stopSale.date_debut,
            au: stopSale.date_fin,
            rooms: stopSale.rooms.map(r => r.id)
        }))
    }

    return (
        <div className={"row"}>
            <div className="col-12">
                <h3>Stop sale</h3>
            </div>
            <div className="col-12 col-md-3 border-end">
                <form onSubmit={submit}>
                    <div className="form-group mt-3">
                        <label htmlFor="">Du</label>
                        <input value={form.du} onChange={e => dispatch(stopSaleActions.setForm({form: "form", field: "du", value: e.target.value}))} type="date" className={"form-control"}/>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="">Au</label>
                        <input value={form.au} onChange={e => dispatch(stopSaleActions.setForm({form: "form", field: "au", value: e.target.value}))} type="date" className={"form-control"}/>
                    </div>
                    <label className={"mt-3"} htmlFor="">Chambres</label>
                    {
                        data.rooms.map(r => (
                            <div key={r.id} className="form-check">
                                <input
                                    onChange={() => dispatch(stopSaleActions.setForm({form: "form", field: "rooms", value: r.id}))}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={form.rooms.find(r_id => r_id === r.id) !== undefined }
                                    id={`room-${r.id}`}
                                />
                                    <label className="form-check-label" htmlFor={`room-${r.id}`}>
                                        {r.categorie}
                                    </label>
                            </div>
                        ))
                    }
                    <button
                        // disabled={loading}
                        type={"submit"} className={"btn btn-success float-end mt-3"}><i className={"bi bi-save"}/>Enregistrer</button>
                </form>
            </div>
            <div className="col-12 col-md-9">
                <table className={"table table-striped table-hover table-bordered"}>
                    <thead className={"table-dark"}>
                    <tr>
                        <th>#</th>
                        <th>Date_du</th>
                        <th>Date_au</th>
                        <th>Chambres</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.stopSales.map((ss,i) => (
                            <tr key={ss.id}>
                                <td>{i+1}</td>
                                <td>{moment(ss.date_debut).format("DD/MM/YYYY")}</td>
                                <td>{moment(ss.date_fin).format("DD/MM/YYYY")}</td>
                                <td>
                                    <ul>
                                        {
                                            ss.rooms.map(room => (
                                                <li key={room.id}>{room.categorie}</li>
                                            ))
                                        }
                                    </ul>
                                </td>
                                <td>
                                    <button disabled={loading} onClick={() => onEditClicked(ss)} className={"text-decoration-underline btn text-warning"}><i className={"bi bi-pencil-square"}/>Modifier</button>
                                    <button disabled={loading} onClick={() => deleteStopSale(ss.id)} className={"btn text-danger text-decoration-underline"}><i className={"bi bi-trash"}/>Supprimer</button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
