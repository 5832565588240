import { useEffect, useState } from "react";
import BaseService from "../../../service/base.service";
import LoadingComponent from "../../../components/loading-component";
import moment from "moment"
import {AiFillDelete} from "react-icons/ai"
const service = new BaseService("Stay");
const FORM_IS = {
  date_debut: "",
  date_fin: "",
  min_stay: "",
  max_stay: "",
};
export default function MinStay() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(FORM_IS);
  useEffect(()=>{
    getAll()
  },[])
  const submit = (e) => {
    setLoading(true);
    e.preventDefault();
    setLoading(true);
    service.create(form).then((res) => {
      getAll();
      setForm(FORM_IS);
    });
  };
  const getAll = () => {
    setLoading(true);
    service
      .getAll()
      .then((res) => setRows(res))
      .catch((error) => console.log(error))
      .finally((_) => setLoading(false));
  };
  const remove = id => {
    setLoading(true)
    service
    .delete(id)
    .then(res => {
        getAll()
    })
    .finally(_ => setLoading(false))
  }
  return (
    <div className={"row"}>
      <div className="col-12">
        <h3>Min stay</h3>
      </div>
      <div className="col-12 col-md-3">
        <form onSubmit={submit}>
          {/* <div className="form-group">
                        <SelectMarche/>
                    </div> */}
          <div className="row p-0">
            <div className="form-group col-6">
              <label htmlFor="">Min stay</label>
              <input
                value={form.min_stay}
                onChange={(e) => setForm({ ...form, min_stay: e.target.value })}
                type="number"
                step={1}
                className={"form-control"}
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="">Max stay</label>
              <input
                value={form.max_stay}
                onChange={(e) => setForm({ ...form, max_stay: e.target.value })}
                type="number"
                step={1}
                className={"form-control"}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="">Date début</label>
            <input
              value={form.date_debut}
              onChange={(e) => setForm({ ...form, date_debut: e.target.value })}
              type="date"
              className={"form-control"}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Date Fin</label>
            <input
              value={form.date_fin}
              onChange={(e) => setForm({ ...form, date_fin: e.target.value })}
              type="date"
              className={"form-control"}
            />
          </div>

          <button
            type="submit"
            className={"btn btn-success btn-sm"}
            style={{ float: "right" }}
          >
            <i className={"bi-save"} />
            Enregistrer
          </button>
        </form>
      </div>

      <div style={{ borderLeft: "1px solid" }} className="col-12 col-md-9">
        <table className="table table-bordered table-sm">
          <thead className={"table-dark"}>
            <tr>
              <th>#</th>
              <th>Début</th>
              <th>Fin</th>
              <th>Min stay </th>
              <th>Max stay </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
                rows.map((r, index) => (
                    <tr key={r.id}>
                        <td>
                           {index+1} 
                        </td>
                        <td>{moment(r.date_debut).format('DD/MM/YYYY')}</td>
                        <td>{moment(r.date_fin).format('DD/MM/YYYY')}</td>
                        <td>{r.min_stay}</td>
                        <td>{r.max_stay}</td>
                        <td>
                            <button className="btn btn-sm btn-danger" onClick={() => remove(r.id)}>Supp.</button>
                        </td>
                    </tr>
                ))
            }
          </tbody>
        </table>
      </div>

      {loading && <LoadingComponent />}
    </div>
  );
}
