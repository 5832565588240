export default function LoadingComponent(){
    return (
        <div
            style={{
                height: "100vh",
                width: "100%",
                position: "fixed",
                top:0,
                left:0,
                display:"flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(0,0,0,.6)",
                zIndex: 9999
            }}
        >
            <p style={{color: "white", fontSize: 24}}>Chargement...</p>
        </div>
    )
}