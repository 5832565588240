import { Link, Outlet, redirect, useNavigate } from "react-router-dom";
import {
  MdEvent,
  MdNightlife,
  MdOutlineBedroomParent,
  MdRealEstateAgent,
  MdSpaceDashboard,
} from "react-icons/md";
import { FaHotel, FaWpforms } from "react-icons/fa";
import { RiPinDistanceFill, RiVidiconFill } from "react-icons/ri";
import { BsFilePerson, BsFillCalendar3WeekFill, BsImage } from "react-icons/bs";
import { IoIosRestaurant } from "react-icons/io";
import { SiMarketo } from "react-icons/si";
import { useEffect } from "react";
import { FiSettings } from "react-icons/fi";

export default function Admin() {
  const navigate = useNavigate();
  // useEffect(() => {
  //   // Check for token in localStorage
  //   const token = localStorage.getItem('token');

  //   if (!token) {
  //     // No token found, redirect to login component
  //     navigate('/login');
  //   }
  // }, [navigate]);

  const logout = () => {
    // localStorage.removeItem('token')
    // navigate('/login')
  }
  return (
    <>
      <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <a className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">
          Hammamet Garden
        </a>
        <button
          className="navbar-toggler d-md-none collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap">
            <Link onClick={logout} className={"nav-link"}>Se déconnecter</Link>
          </li>
        </ul>
      </nav>
      <div id="admin-container" className="container-fluid">
        <div className="row">
          <nav
            id="sidebarMenu"
            className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
          >
            <div className="sidebar-sticky pt-3">
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link className="nav-link" to="/" target={"_blank"}>
                    <MdSpaceDashboard size={24} style={{ marginRight: 8 }} />
                    Redirection site client
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"information-hotel"}>
                    <FaHotel
                      size={18}
                      style={{ marginRight: 12, marginLeft: 4 }}
                    />
                    Informations hôtel
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"situation"}>
                    <RiPinDistanceFill size={24} style={{ marginRight: 8 }} />
                    Situation
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"hebergements"}>
                    <MdOutlineBedroomParent
                      size={24}
                      style={{ marginRight: 8 }}
                    />
                    Hebergements
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"conditions-chambres"}>
                    <FaWpforms size={24} style={{ marginRight: 8 }} />
                    Gestion de vente
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"reservations"}>
                    <BsFillCalendar3WeekFill
                      size={18}
                      style={{ marginRight: 12, marginLeft: 4 }}
                    />
                    Réservations
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"installations-espaces"}>
                    <MdRealEstateAgent size={24} style={{ marginRight: 8 }} />
                    Installations et espaces
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"salles-conferences"}>
                    <RiVidiconFill size={24} style={{ marginRight: 8 }} />
                    Salles de conférences
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"gastronomie"}>
                    <IoIosRestaurant size={24} style={{ marginRight: 8 }} />
                    Gastronomie
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"animation-loisir"}>
                    <MdNightlife size={24} style={{ marginRight: 8 }} />
                    Animations et loisirs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"evenements"}>
                    <MdEvent size={24} style={{ marginRight: 8 }} />
                    Evenements
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"marches"}>
                    <SiMarketo size={24} style={{ marginRight: 8 }} />
                    Marchés
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"gallery"}>
                    <BsImage size={24} style={{ marginRight: 8 }} />
                    Gallerie
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"avis-clients"}>
                    <BsFilePerson size={24} style={{ marginRight: 8 }} />
                    Avis clients
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"page-conditions-paiements"}>
                    <BsFilePerson size={24} style={{ marginRight: 8 }} />
                    Conditions de paiements
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"parametres"}>
                    <FiSettings size={24} style={{ marginRight: 8 }} />
                    Paramètres
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <main id="admin" role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}
