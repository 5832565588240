export default function Restaurants({ data }) {
    return (
        <section id="restaurants" className="ftco-section ftco-menu bg-light">
            <div className="container-fluid px-md-4">
                <div className="row justify-content-center mb-5 pb-3">
                    <div className="col-md-7 heading-section text-center aos-animate" data-aos="fade-up">
                        <span className="subheading">Restaurants</span>
                        <h2>Restaurants</h2>
                    </div>
                </div>
                <div className="row">
                    {
                        data.map(resto => (
                            <div key={resto.id} className="col-12 col-md-6 d-flex">
                                <div className="pricing-entry rounded aos-animate" data-aos="fade-up">
                                    <img width={"100%"} height={300} src={resto.image} alt="" />
                                    <div className="desc p-4">
                                        <div className="d-md-flex text align-items-center justify-content-between">
                                            <h3>{resto.label}</h3>
                                            <span className="price">{resto.type}</span>
                                        </div>
                                        {/* <div className="d-block">
                                            <p>A small river named Duden flows by their place and supplies</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        ))
                    }



                </div>
            </div>
        </section>
    )
}