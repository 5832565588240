import axios from "axios";
import { useState } from "react";
import { BiSave } from "react-icons/bi";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import LoadingComponent from "../../../components/loading-component";
import { API_URL } from "../../../config/constants";

export default function Profil() {
  const [showNouveauMdp, setShowNouveauMdp] = useState(false);
  const [showConfirmationMdp, setShowConfirmationMdp] = useState(false);
  const [form, setForm] = useState({
    nouveauMdp: "",
    confirmationMdp: "",
  });
  const [loading, setLoading] = useState(false);
  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const submit = async (e) => {
    e.preventDefault();
    const { nouveauMdp, confirmationMdp } = form;
    if (nouveauMdp.length < 8)
      return alert("Mot de passe doit avoir au moins 8 caractères");
    if (nouveauMdp !== confirmationMdp)
      return alert("Mots de passes non conformes!");

    setLoading(true);

    await axios
      .post(
        API_URL + "auth/change-password",
        { nouveauMdp },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        alert(res.data);
        localStorage.removeItem("token");
        window.location.reload();
      })
      .catch((error) => {
        alert(error);
      });
    setLoading(false);
  };
  return (
    <div>
      <form onSubmit={submit}>
        <div className="row">
          <div className="col-12 col-md-3">
            <div className="input-group mb-3">
              <input
                value={form.nouveauMdp}
                name="nouveauMdp"
                onChange={handleChangeForm}
                type={showNouveauMdp ? "text" : "password"}
                className="form-control"
                placeholder="Nouveau mot de passe"
              />
              <div className="input-group-append">
                <button
                  onClick={() => setShowNouveauMdp(!showNouveauMdp)}
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  {showNouveauMdp ? <BsEye /> : <BsEyeSlash />}
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="input-group mb-3">
              <input
                value={form.confirmationMdp}
                name="confirmationMdp"
                onChange={handleChangeForm}
                type={showConfirmationMdp ? "text" : "password"}
                className="form-control"
                placeholder="Confirmation mot de passe"
              />
              <div className="input-group-append">
                <button
                  onClick={() => setShowConfirmationMdp(!showConfirmationMdp)}
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  {showConfirmationMdp ? <BsEye /> : <BsEyeSlash />}
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <button className="btn btn-primary" style={{ padding: 12 }}>
              <BiSave /> Enregistrer
            </button>
          </div>
        </div>
      </form>
      {loading && <LoadingComponent />}
    </div>
  );
}
