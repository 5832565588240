import { useEffect, useState } from "react";
import { BiCheck, BiPlus } from "react-icons/bi";
import { CgClose, CgSpinner } from "react-icons/cg";
import BaseService from "../../../service/base.service";
const service = new BaseService("Supplement");
const hebergementService = new BaseService("Hebergement");
const FORM_IS = { id: "", label: "", id_hebergement: "" };
export default function GestionSupplements() {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(FORM_IS);
  const [rows, setRows] = useState([]);
  const [hebergements, setHebergements] = useState([]);
  const resetForm = () => {
    setForm(FORM_IS);
  };
  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    const newForm = {...form, id_hebergement: form.id_hebergement === "" ? null : form.id_hebergement}
    if (form.id !== "")
      service
        .save(form.id, newForm)
        .then((res) => {
          let rowsWU = [...rows];
          const index = rows.findIndex((row) => row.id === form.id);
          if (index !== -1) {
            rowsWU[index] = form;
            setRows(rowsWU);
          } else {
            alert("Erreur");
            console.error(res);
          }
          resetForm();
          document.getElementById("input-supplement").focus();
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    else
      service
        .create(newForm)
        .then((res) => {
          setRows([...rows, res]);
          resetForm();
          document.getElementById("input-supplement").focus();
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
  };
  useEffect(() => {
    getAll();
    getAllHebergements();
  }, []);
  const getAll = () => {
    setLoading(true);
    service
      .getAll()
      .then((res) => setRows(res))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };
  const getAllHebergements = () => {
    hebergementService
      .getAll()
      .then((res) => setHebergements(res))
      .catch((error) => console.log(error));
  };

  const onUpdateClicked = (id) => {
    setLoading(true);
    service
      .findBy("id", id)
      .then((res) => {
        console.log(res);
        setForm({...res[0], id_hebergement: res[0].id_hebergement || ""})
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const onDeleteClicked = (id) => {
    setLoading(true);
    service
      .delete(id)
      .then((res) => getAll())
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };
  if (hebergements.length === 0) return <>Chargement des hébérgements...</>;
  return (
    <div className="row">
      <div className="col-12">
        <strong>Gestion des Suppléments</strong>
        <button
          onClick={resetForm}
          data-toggle="modal"
          data-target="#form"
          className="ml-2 btn btn-primary"
        >
          <BiPlus />
          Ajouter
        </button>
      </div>
      <div
        className={`modal fade`}
        id="form"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Formulaire Suppléments
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <form onSubmit={submit}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="input-supplement">Supplément</label>
                        <select
                          value={form.label}
                          onChange={(e) =>
                            setForm({ ...form, label: e.target.value })
                          }
                          required
                          id="input-supplement"
                          className="form-control"
                        >
                          <option value="">Veuillez séléctionner</option>
                          <option value="Supplément Single">
                            Supplément Single
                          </option>
                          <option value="supplement Vue piscine">
                            Supplément vue piscine
                          </option>
                          <option value="supplement Vue jardin">
                            Supplément vue jardin
                          </option>
                          
                        </select>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="select-hebergement">
                          Liason chambre
                        </label>
                        <select
                          className="form-control"
                          value={form.id_hebergement}
                          onChange={(e) =>
                            setForm({ ...form, id_hebergement: e.target.value })
                          }
                          id="select-hebergement"
                        >
                          <option value="">-- Chambre --</option>
                          {hebergements.map((h) => (
                            <option key={h.id} value={h.id}>
                              {h.categorie}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-12 col-md-2">
                      <button
                        disabled={loading}
                        className="btn btn-primary"
                        style={{ marginTop: 30 }}
                      >
                        {loading ? (
                          <>
                            <CgSpinner />
                            Chargement
                          </>
                        ) : (
                          <>
                            <BiCheck />
                            Valider
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                <CgClose />
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
      <table className="mt-4 table table-bordered table-striped table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Label</th>
            <th>Chambre</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={"100%"}>chargement...</td>
            </tr>
          ) : (
            rows.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{row.label}</td>
                <td>
                  {
                    hebergements.find((h) => h.id === row.id_hebergement)
                      ?.categorie
                  }
                </td>
                <td>
                  <button
                    data-toggle="modal"
                    data-target="#form"
                    onClick={() => onUpdateClicked(row.id)}
                  >
                    Modifier
                  </button>
                  <button onClick={() => onDeleteClicked(row.id)}>
                    Supprimer
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
